import React, {Component, ReactNode} from 'react';
import {ProposalCompany} from "../../redux/contact/types";
import VerifyCompanyInformationForm from "../forms/VerifyCompanyInformationForm";

interface OwnProps {
    company : ProposalCompany;
    type: string;
    handleSubmit : (company : ProposalCompany, type: string) => Promise<void>;
    updateFormValidationIsValid : (isValid : boolean) => void;
}

type Props = OwnProps;

class VerifyCompanyInformation extends Component<Props>
{
    public render() : ReactNode
    {
        const {company, handleSubmit, type, updateFormValidationIsValid} = this.props;

        return (
            <React.Fragment>
                <div className="d-flex justify-content-center">
                    <p>
                        Please use the form below to verify the {type} information we have for you, then click
                        "<span className="text-primary">Continue</span>".
                    </p>
                </div>
                <VerifyCompanyInformationForm
                    company={company}
                    type={type}
                    handleSubmit={handleSubmit}
                    updateFormValidationIsValid={updateFormValidationIsValid}
                />
            </React.Fragment>
        );
    }
}

export default (VerifyCompanyInformation);