import React, {Component, ReactNode} from 'react';
import {Proposal} from "../../redux/contact/types";

interface OwnProps {
    proposalTitle: string
    proposal: Proposal;
}

type Props = OwnProps;

class ProposalRejected extends Component<Props>
{
    public render() : ReactNode
    {
        const {proposalTitle, proposal} = this.props;

        return (
            <React.Fragment>
                <div className="d-flex justify-content-center flex-column">
                    <p>
                        You have rejected the {proposal.isQuote ? 'quote' : 'proposal'} provided for &nbsp;
                        <span className="text-primary">{proposalTitle}</span>. Your rejection notice
                        has been submitted to the Aquatic Control team. An Aquatic Control representative will
                        review your provided rejection reason and you will be notified when your {proposal.isQuote ? 'quote' : 'proposal'} has been
                        updated in the event further information is required. ~Aquatic Control.
                    </p>
                </div>
            </React.Fragment>
        );
    }
}

export default (ProposalRejected);