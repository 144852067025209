import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";
import {contactReducer} from "./contact/reducers";

const {
    createReduxHistory,
    routerMiddleware,
    routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });

const reducer = combineReducers({
    router: routerReducer,
    contact: contactReducer,
});

export const store = configureStore({
    reducer: reducer,
    // @ts-expect-error no idea
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware),
});

export const history = createReduxHistory(store);
export type AppState = ReturnType<typeof reducer>;
