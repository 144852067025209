import { Component, ReactNode } from 'react';
import Nav from 'react-bootstrap/Nav';
import BsNavbar from 'react-bootstrap/Navbar';
import NavBase from './NavBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {type ProposalContact} from "@/redux/contact/types.ts";

interface OwnProps {
    proposalContact: ProposalContact;
}

type Props = OwnProps;

class Navbar extends Component<Props>
{
    public render(): ReactNode {

        const {proposalContact} = this.props;

        return (
            <NavBase>
                <div className="text-right mt-3">
                    <BsNavbar.Toggle aria-controls="basic-navbar-nav" />
                    <BsNavbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <div className="d-flex flex-row justify-content-end align-items-center pt-2">
                                <FontAwesomeIcon icon={faUser} size="1x" className="text-primary" />
                                <span className="ps-1 pt-1">{proposalContact?.name ?? ''}</span>
                            </div>
                        </Nav>
                    </BsNavbar.Collapse>
                </div>
            </NavBase>
        );
    }

}

export default (Navbar);
