import {Component, ReactNode} from 'react';
import {ProposalContact} from "../../redux/contact/types";
import VerifyContactInformationForm from "../forms/VerifyContactInformationForm";

interface OwnProps {
    contact : ProposalContact;
    type: string;
    handleSubmit : (contact : ProposalContact, type: string) => Promise<void>;
    updateFormValidationIsValid : (isValid : boolean) => void;
}

type Props = OwnProps;

class VerifyContactInformation extends Component<Props>
{
    public render() : ReactNode
    {
        const {contact, handleSubmit, type, updateFormValidationIsValid} = this.props;

        return (
            <>
                <div className="d-flex justify-content-center">
                    <p>
                        Please use the form below to verify the {type} information we have for you, then click
                        "<span className="text-primary">Continue</span>".
                    </p>
                </div>
                <VerifyContactInformationForm
                    contact={contact}
                    type={type}
                    handleSubmit={handleSubmit}
                    updateFormValidationIsValid={updateFormValidationIsValid}
                />
            </>
        );
    }
}

export default (VerifyContactInformation);
