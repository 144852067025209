import React, {Component, ReactNode} from "react";
import {Proposal} from "../../redux/contact/types";
import Navbar from "../navigation/Navbar";
import {Container, Row, Col, Spinner} from 'react-bootstrap';
import Footer from "../layout/Footer";
import PdfRenderer from "../pdf/PdfRenderer";
import ProposalModal from "../modal/PropoalModal";

interface OwnProps {
    handleConfirm: (proposalId: string) => void;
    proposals: Array<Proposal>;
    rejectLakeOrProposal: (proposalState: number, proposalId: string, reason: string, rejectionType: string) => void;
}

interface State {
    showProposalModal: boolean;
    proposal: Proposal|undefined;
    pdfDocument: string|undefined;
}

class SelectProposal extends Component<OwnProps, State>
{
    public readonly state = {
        showProposalModal: false,
        proposal: undefined as Proposal|undefined,
        pdfDocument: undefined,
    };

    public render() : ReactNode
    {
        const {proposals, handleConfirm, rejectLakeOrProposal} = this.props;
        const {proposal, pdfDocument, showProposalModal} = this.state;

        let availableProposals = proposals.filter(proposal =>
            ![500, 700, 900].includes(proposal.proposalState) && !proposal.isQuote);
        let rejectedProposals = proposals.filter(proposal => [500, 700].includes(proposal.proposalState) && !proposal.isQuote);
        let completedProposals = proposals.filter(proposal => proposal.proposalState === 900 && !proposal.isQuote);

        let availableQuotes = proposals.filter(proposal =>
            ![500, 700, 900].includes(proposal.proposalState) && proposal.isQuote);
        let rejectedQuotes = proposals.filter(proposal => [500, 700].includes(proposal.proposalState) && proposal.isQuote);
        let completedQuotes = proposals.filter(proposal => proposal.proposalState === 900 && proposal.isQuote);

        return (
            <React.Fragment>
                <Navbar proposalContact={proposals[0].proposalContact} />
                <Container className="mb-5 pb-5">
                    <Row className="bg-white mt-4 mb-3 py-4 px-2 shadow-sm rounded row">
                        {proposal !== undefined && showProposalModal && (
                            <React.Fragment>
                                <React.Suspense fallback={<div/>}>
                                    {pdfDocument === undefined && (<PdfRenderer
                                        proposalPdf={{ signature: null, printedName: null}}
                                        proposal={proposal}
                                        onFinish={this.handleBoardPdfEnd}
                                        options={proposal.options}
                                    />)}
                                </React.Suspense>
                                <ProposalModal
                                    show={showProposalModal}
                                    handleClose={() => this.setState({showProposalModal: false})}
                                    handleConfirm={() => handleConfirm(proposal.id)}
                                    proposalId={proposal.id}
                                    rejectLakeOrProposal={rejectLakeOrProposal}
                                    pdfDocument={pdfDocument}
                                    proposalTitle={proposal.title}
                                    isQuote={proposal.isQuote}
                                    isListing={true}
                                >
                                    {pdfDocument !== undefined ? (
                                        <React.Fragment>
                                            <iframe
                                                src={pdfDocument}
                                                frameBorder={0}
                                                width={'100%'}
                                                height={'500px;'}
                                                className="bg-white mt-0"
                                                title="pdf-iframe"
                                            />
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            &nbsp; Loading
                                        </React.Fragment>
                                    )}
                                </ProposalModal>
                            </React.Fragment>
                        )}

                        <Col lg={12}>
                            {availableProposals.length > 0 && (
                            <>
                            <h3 className="text-primary">Available Proposals</h3>
                            <p>Please select the proposal you wish to complete from the available proposals listed below by
                                clicking "Review Proposal"</p>
                            <table className="table-sm table-bordered w-100 select-proposal">
                                <thead className="bg-primary text-white">
                                    <tr>
                                        <th className="col-2">Order Number</th>
                                        <th>Proposal Title / Property</th>
                                        <th className="col-1">Order Date</th>
                                        <th className="col-1">Total</th>
                                        <th className="col-2"> &nbsp; </th>
                                    </tr>
                                </thead>
                                <tbody className="text-secondary">
                                {availableProposals.map( (proposal : Proposal, index) => (
                                    <tr key={`${index}-available`}>
                                        <td className="order-number-column">{proposal.title}</td>
                                        <td>{proposal.sectionTitle} / {proposal.property.name}</td>
                                        <td>{proposal.orderDate}</td>
                                        <td>{proposal.proposalTotal}</td>
                                        <td>
                                            <div className="justify-content-center d-flex">
                                                <button className="btn btn-success m-0" onClick={() => {
                                                    handleConfirm(proposal.id);
                                                }}>
                                                    Review Proposal
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            </>
                            )}
                            {rejectedProposals.length > 0 && (
                                <React.Fragment>
                                    <h3 className="text-primary mt-4">Rejected Proposals</h3>
                                    <p>Rejected proposals will be reviewed by the Aquatic Control team. Once resolved, you will
                                    receive an email notice from Aquatic Control and your updated proposal will show in the
                                    Available Proposals list above.</p>
                                    <table className="table-sm table-bordered w-100 select-proposal">
                                        <thead className="bg-primary text-white">
                                        <tr>
                                            <th className="col-2">Order Number</th>
                                            <th>Proposal Title / Property</th>
                                            <th className="col-1">Rejected Step</th>
                                            <th className="col-2"> &nbsp; </th>
                                        </tr>
                                        </thead>
                                        <tbody className="text-secondary">
                                        {rejectedProposals.map( (proposal : Proposal, index) => (
                                            <tr key={`${index}-rejected`}>
                                                <td className="order-number-column">{proposal.title}</td>
                                                <td>{proposal.sectionTitle} / {proposal.property.name}</td>
                                                <td>
                                                    {proposal.proposalState === 500 ? 'Lake Information' : 'Review Proposal'}
                                                </td>
                                                <td>
                                                    <div className="justify-content-center d-flex">
                                                        <button
                                                            className="btn btn-info m-0"
                                                            onClick={() => handleConfirm(proposal.id)}
                                                        >
                                                            View Proposal
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </React.Fragment>
                            )}
                            {completedProposals.length > 0 && (
                                <React.Fragment>
                            <h3 className="text-primary mt-4">Completed Proposals</h3>
                                <table className="table-sm table-bordered w-100 select-proposal">
                                    <thead className="bg-primary text-white">
                                    <tr>
                                        <th className="col-2">Order Number</th>
                                        <th>Proposal Title / Property</th>
                                        <th className="col-2"> &nbsp; </th>
                                    </tr>
                                    </thead>
                                    <tbody className="text-secondary">
                                    {completedProposals.map( (proposal : Proposal, index) => (
                                        <tr key={`${index}-completed`}>
                                            <td className="order-number-column">{proposal.title}</td>
                                            <td>{proposal.sectionTitle} / {proposal.property.name}</td>
                                            <td>
                                                <div className="justify-content-center d-flex">
                                                    <button
                                                        className="btn btn-info m-0"
                                                        onClick={() => handleConfirm(proposal.id)}
                                                    >
                                                        View Proposal
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </React.Fragment>
                            )}
                            {availableQuotes.length > 0 && (
                                <>
                                    <h3 className="text-primary mt-4">Available Quotes</h3>
                                    <p>Please select the order you wish to complete from the available quotes listed below by
                                        clicking "Review Quote"</p>
                                    <table className="table-sm table-bordered w-100 select-proposal">
                                        <thead className="bg-primary text-white">
                                        <tr>
                                            <th className="col-2">Order Number</th>
                                            <th>Quote Title / Property</th>
                                            <th className="col-1">Order Date</th>
                                            <th className="col-1">Total</th>
                                            <th className="col-2"> &nbsp; </th>
                                        </tr>
                                        </thead>
                                        <tbody className="text-secondary">
                                        {availableQuotes.map( (proposal : Proposal, index) => (
                                            <tr key={`${index}-available`}>
                                                <td className="order-number-column">{proposal.title}</td>
                                                <td>{proposal.sectionTitle ? proposal.sectionTitle : `Quote for ${proposal.proposalCompany.name}`} / {proposal.property.name}</td>
                                                <td>{proposal.orderDate}</td>
                                                <td>{proposal.proposalTotal}</td>
                                                <td>
                                                    <div className="justify-content-center d-flex">
                                                        <button className="btn btn-success m-0" onClick={() => {
                                                            handleConfirm(proposal.id);
                                                        }}>
                                                            Review Quote
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                            {completedQuotes.length > 0 && (
                                <React.Fragment>
                                    <h3 className="text-primary mt-4">Completed Quotes</h3>
                                    <table className="table-sm table-bordered w-100 select-proposal">
                                        <thead className="bg-primary text-white">
                                        <tr>
                                            <th className="col-2">Order Number</th>
                                            <th>Quote Title / Property</th>
                                            <th className="col-2"> &nbsp; </th>
                                        </tr>
                                        </thead>
                                        <tbody className="text-secondary">
                                        {completedQuotes.map( (proposal : Proposal, index) => (
                                            <tr key={`${index}-completed`}>
                                                <td className="order-number-column">{proposal.title}</td>
                                                <td>{proposal.sectionTitle ? proposal.sectionTitle : `Quote for ${proposal.proposalCompany.name}`} / {proposal.property.name}</td>
                                                <td>
                                                    <div className="justify-content-center d-flex">
                                                        <button
                                                            className="btn btn-info m-0"
                                                            onClick={() => handleConfirm(proposal.id)}
                                                        >
                                                            View Quote
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </React.Fragment>
                            )}
                            {rejectedQuotes.length > 0 && (
                                <React.Fragment>
                                    <h3 className="text-primary mt-4">Rejected Quotes</h3>
                                    <p>Rejected quotes will be reviewed by the Aquatic Control team. Once resolved, you will
                                        receive an email notice from Aquatic Control and your updated quote will show in the
                                        Available Quotes list above.</p>
                                    <table className="table-sm table-bordered w-100 select-proposal">
                                        <thead className="bg-primary text-white">
                                        <tr>
                                            <th className="col-2">Order Number</th>
                                            <th>Quote Title / Property</th>
                                            <th className="col-1">Rejected Step</th>
                                            <th className="col-2"> &nbsp; </th>
                                        </tr>
                                        </thead>
                                        <tbody className="text-secondary">
                                        {rejectedQuotes.map( (proposal : Proposal, index) => (
                                            <tr key={`${index}-rejected`}>
                                                <td className="order-number-column">{proposal.title}</td>
                                                <td>{proposal.sectionTitle ? proposal.sectionTitle : `Quote for ${proposal.proposalCompany.name}`} / {proposal.property.name}</td>
                                                <td>
                                                    {proposal.proposalState === 500 ? 'Lake Information' : 'Review Quote'}
                                                </td>
                                                <td>
                                                    <div className="justify-content-center d-flex">
                                                        <button
                                                            className="btn btn-info m-0"
                                                            onClick={() => handleConfirm(proposal.id)}
                                                        >
                                                            View Quote
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </React.Fragment>
                            )}
                        </Col>
                    </Row>
                    <Footer />
                </Container>
            </React.Fragment>
        );
    }

    private handleBoardPdfEnd = async (blob: any) : Promise<void> => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
            const base64data = reader.result!.toString();
            this.setState({pdfDocument: base64data});
        };
    };
}

export default (SelectProposal);