import React, {Component, ReactNode} from 'react';
import {AdditionalLake, Coordinates, Lake} from '@/redux/contact/types.ts';
import GoogleMapReact, {ClickEventValue} from 'google-map-react';
import PinSelected from '../../images/map-marker.png';
import AdditionalLakeModal from '../modal/AdditionalLakeModal';
import CloseRow from '../../images/table-row-close.svg';
import AdditionalLakesForm from "../forms/AdditionalLakesForm";

interface OwnProps {
    lakes: Array<Lake>;
    handleSubmit : (lakes : Array<Lake>) => Promise<void>;
    updateFormValidationIsValid : (isValid : boolean) => void;
    additionalLakes: Array<AdditionalLake>;
}

interface Coords {
    lat: number;
    lng: number;
    text: string;
};

interface State {
    pinEnabled: boolean;
    additionalLakes: Array<AdditionalLake>;
    showAdditionalLakeModal: boolean;
    additionalLakeCoordinates: Coordinates;
}

type Props = OwnProps;

class VerifyLakeInformation extends Component<Props, State>
{
    public readonly state = {
        pinEnabled: false,
        additionalLakes: this.props.additionalLakes,
        showAdditionalLakeModal: false,
        showRejectLakeInformationModal: false,
        additionalLakeCoordinates: {lat: 0.0, lng: 0.0}
    };

    public render() : ReactNode
    {
        const {lakes, handleSubmit, updateFormValidationIsValid} = this.props;

        const defaultProps = {
            center: {
                lat: lakes[0].coordinates.lat,
                lng: lakes[0].coordinates.lng
            },
            zoom: 15
        };

        const MapMarker: any = (coords: Coords) => <span
            className="bg-white rounded-circle lake-marker-custom d-flex justify-content-center
                                align-items-center progress-text border border-danger">{coords.text}</span>;

        const draggableCursor = this.state.pinEnabled ? `url(${PinSelected}), pointer` : 'pointer';

        return (
            <React.Fragment>
                <div className="d-flex justify-content-center flex-column">
                    <p>
                        Please use the map below to verify the lake information we have for you, then click
                        "<span className="text-primary">Accept Lake Information</span>".</p>

                        <p>You can identify additional property lakes using the map below. Simply position the pin icon
                        over the additional lake you wish to identify and left click your mouse. Enter a name for you
                        additional lake in the modal provided then click
                        "<span className="text-primary">Confirm</span>". You can disable this feature by toggling the
                            "<span className="text-primary">Additional Lakes Pin</span>" button.</p>

                        <p>(Note: Lakes identified with the Additional Lakes Pin will NOT be available for treatment
                        via the currently selected proposal. If additional lakes require treatment, click
                        "<span className="text-primary">Reject Lake Information</span>" and notate the requested
                        treatment in the rejection reason comments.)
                    </p>
                    <div className="d-flex flex-row justify-content-end">
                        {this.state.pinEnabled ? (
                            <button className="btn btn-success mb-2" onClick={this.handleToggleAdditionalLakes}>
                                Additional Lakes Pin <span className="badge badge-light">On</span>
                            </button>
                        ) : (
                            <button
                                className="btn btn-outline-secondary mb-2"
                                onClick={this.handleToggleAdditionalLakes}
                            >
                                Additional Lakes Pin <span className="badge badge-light border">Off</span>
                            </button>
                        )}
                    </div>
                    <div className="googleMapsContainer rounded overflow-hidden mb-3">
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyBnRQRh_Dfxk-JJuDnfv-O8jlE2B5vEC9s" }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                            style={{
                                width: "100%",
                                height: "100%"
                            }}
                            onClick={this.handleMapClick}
                            options={{ draggableCursor: draggableCursor, draggingCursor: 'default' }}
                            onGoogleApiLoaded={this.onMapLoaded}
                            yesIWantToUseGoogleMapApiInternals={true}
                        >
                            {lakes.map( (lake : Lake, index) => (
                                <MapMarker
                                    key={`lake-${index}`}
                                    lat={lake.coordinates.lat}
                                    lng={lake.coordinates.lng}
                                    text={index+1}
                                />
                            ))}
                            {this.state.additionalLakes.map( (lake : AdditionalLake, index) => (
                                <MapMarker
                                    key={`addLake-${index}`}
                                    lat={lake.coordinates.lat}
                                    lng={lake.coordinates.lng}
                                    text={lakes.length+index+1}
                                />
                            ))}
                        </GoogleMapReact>
                        <div
                            className="text-white rounded-circle lake-marker-pin d-flex justify-content-center
                                align-items-center progress-text border bg-danger">1</div>
                    </div>
                    <h3 className="text-primary">Proposal Lakes</h3>
                    <table className="table-sm table-bordered w-100 select-proposal">
                        <thead className="bg-primary text-white">
                        <tr>
                            <th>Marker #</th>
                            <th>Lake</th>
                            <th>Coordinates</th>
                        </tr>
                        </thead>
                        <tbody className="text-secondary">
                        {lakes.map( (lake : Lake, index) => (
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{lake.name}</td>
                                <td>
                                    {lake.coordinates.lat} <span>&#176;</span> N &nbsp;
                                    {lake.coordinates.lng} <span>&#176;</span> W
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {this.state.additionalLakes.length > 0 && (
                        <React.Fragment>
                            <h3 className="text-primary mt-3">Additional Lakes</h3>
                            <table className="table-sm table-bordered w-100 select-proposal">
                                <thead className="bg-primary text-white">
                                <tr>
                                    <th>Marker #</th>
                                    <th>Lake</th>
                                    <th>Coordinates</th>
                                    <th>&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody className="text-secondary">
                                {this.state.additionalLakes.map( (lake : AdditionalLake, index) => (
                                    <tr key={index}>
                                        <td>{lakes.length+index+1}</td>
                                        <td>{lake.name}</td>
                                        <td>
                                            {lake.coordinates.lat.toString().substring(0, 8)} <span>&#176;</span>
                                            N &nbsp;
                                            {lake.coordinates.lng.toString().substring(0, 8)} <span>&#176;</span> W
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-center pe-2 cursor-pointer">
                                                <img
                                                    src={CloseRow}
                                                    onClick={() => this.removeAdditionalLake(index)}
                                                    alt="Remove Additional Lake"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </React.Fragment>
                    )}
                </div>
                <AdditionalLakeModal
                    show={this.state.showAdditionalLakeModal}
                    handleClose={this.handleCloseAdditionalLakeModal}
                    handleConfirm={this.handleConfirmAdditionalLakeModal}
                    coordinates={this.state.additionalLakeCoordinates}
                />
                <AdditionalLakesForm
                    handleSubmit={handleSubmit}
                    updateFormValidationIsValid={updateFormValidationIsValid}
                    additionalLakes={this.state.additionalLakes}
                />
            </React.Fragment>
        );
    }

    private handleMapClick = (value: ClickEventValue): void => {
        if (this.state.pinEnabled) {
            let coordinates = {lat: value.lat, lng: value.lng}
            this.setState({additionalLakeCoordinates: coordinates});
            this.setState({showAdditionalLakeModal: true});
        }
    };

    private handleToggleAdditionalLakes = (): void => {
        this.setState({pinEnabled: !this.state.pinEnabled});
    }

    private onMapLoaded = (maps: { map: any; maps: any; ref: Element | null; }): void => {
        const bounds = new maps.maps.LatLngBounds();
        const lakes = this.props.lakes;

        lakes.filter(
            lake => lake.coordinates.lat !== undefined && lake.coordinates.lng !== undefined
        ).forEach(
            lake => {bounds.extend(new maps.maps.LatLng(lake.coordinates.lat, lake.coordinates.lng));}
        );

        maps.map.fitBounds(bounds);
        let zoom = maps.map.getZoom();
        let zoomOut = lakes.length > 1 ? -1 : -4;
        maps.map.setZoom(zoom + zoomOut);
    }

    private handleCloseAdditionalLakeModal = () => {
        this.setState({showAdditionalLakeModal: false});
    };

    private handleConfirmAdditionalLakeModal = async (lakeName: string, coordinates: Coordinates) => {
        this.handleCloseAdditionalLakeModal();
        let additionalLake = {name: lakeName, coordinates: coordinates};
        this.setState({additionalLakes: [...this.state.additionalLakes, additionalLake]});
    };

    private removeAdditionalLake = (index: number) => {
        let additionalLakes = this.state.additionalLakes;
        additionalLakes.splice(index, 1);
        this.setState({additionalLakes: [...additionalLakes]});
    }
}

export default (VerifyLakeInformation);
