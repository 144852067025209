import {Component, ReactNode} from 'react';
import CircularLoadingIndicator from './CircularLoadingIndicator';

class FullPageLoadingIndicator extends Component
{
    public render() : ReactNode
    {
        return (
            <div className="d-flex justify-content-center align-items-center w-100 flex-column vh-100">
                <CircularLoadingIndicator/>
            </div>
        );
    }
}

export default (
    FullPageLoadingIndicator
);
