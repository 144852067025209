import {Component, createRef, ReactNode} from 'react';
import {Formik} from 'formik';
import * as yup from 'yup';
import {AdditionalLake} from "@/redux/contact/types.ts";

interface OwnProps
{
    additionalLakes: Array<AdditionalLake>;
    handleSubmit : (additionalLakes : Array<AdditionalLake>) => Promise<void>;
    updateFormValidationIsValid : (isValid : boolean) => void;
}

interface NameToValueMap
{
    [key: string]: any;
}

type Props = OwnProps;

class AdditionalLakesForm extends Component<Props>
{
    public form : any;

    constructor(props : Props) {
        super(props);
        this.form = createRef();
    }

    async componentDidMount() {
        const {updateFormValidationIsValid} = this.props;
        const errors = await this.form.current.validateForm();
        updateFormValidationIsValid(Object.keys(errors).length === 0);

        if (this.form.current) {
            this.form.current.resetForm();
        }
    }

    public render() : ReactNode
    {
        const schema = yup.object({
        });

        const initialValues : NameToValueMap = {
        };

        return (
            <Formik
                onSubmit={this.handleSubmit}
                initialValues={initialValues}
                validationSchema={schema}
                innerRef={this.form}
            >
                {({
                      handleSubmit
                  }) => (
                      <>
                        <form noValidate onSubmit={handleSubmit} id="mainFormContent">
                        </form>
                      </>
                )}
            </Formik>
        );
    }

    // @ts-ignore
    private handleChange = async (e: React.FormEvent<HTMLFormElement> | undefined) => {
        await this.form.current.handleChange(e);
        const {updateFormValidationIsValid} = this.props;
        const errors = await this.form.current.validateForm();
        updateFormValidationIsValid(Object.keys(errors).length === 0);
    };

    private handleSubmit = async () => {

        const {handleSubmit, additionalLakes} = this.props;
        await handleSubmit(additionalLakes);

        if (this.form.current) {
            this.form.current.resetForm();
        }
    };
}

export default (AdditionalLakesForm);
