import {Component, ReactNode} from "react";
import ModalBase from "./ModalBase";
import RejectionReasonForm from "../forms/RejectionReasonForm";

interface OwnProps
{
    show : boolean;
    handleClose : () => void;
    handleConfirm : (reason: string) => Promise<void>;
    proposalTitle: string;
    loading: boolean;
    proposalType?: string;
}

type Props = OwnProps;

class RejectProposalModal extends Component<Props>
{
    public render() : ReactNode
    {
        const {show, handleConfirm, handleClose, loading, proposalType} = this.props;

        let proposalTypeDisplay = 'Proposal';

        if (proposalType) {
            proposalTypeDisplay = proposalType;
        }

        return (
            <ModalBase
                show={show}
                handleClose={handleClose}
                title={`Need an Edit to Your ${proposalTypeDisplay}?`}
                confirmText="Submit Edits"
                refForm="rejectionReasonForm"
                size="md"
                btnClass="smallModalBtn"
                loading={loading}
            >
                <RejectionReasonForm handleSubmit={handleConfirm} type={proposalTypeDisplay} />
            </ModalBase>
        );
    }
}

export default (RejectProposalModal);
