import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import App from './App';
import {history, store} from './redux';
import * as serviceWorker from './serviceWorker';
import './custom.scss';
import { HistoryRouter as Router } from "redux-first-history/rr6";

const container = document.getElementById('root');
const root = createRoot(container ?? document.body);

root.render(
    <Provider store={store}>
        <Router history={history}>
            <App />
        </Router>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
