import React, {Component, ReactNode} from 'react';
import {Property} from "../../redux/contact/types";
import VerifyPropertyInformationForm from "../forms/VerifyPropertyInformationForm";

interface OwnProps {
    property: Property;
    handleSubmit : (property : Property) => Promise<void>;
    updateFormValidationIsValid : (isValid : boolean) => void;
}

type Props = OwnProps;

class VerifyPropertyInformation extends Component<Props>
{
    public render() : ReactNode
    {
        const {property, handleSubmit, updateFormValidationIsValid} = this.props;

        return (
            <React.Fragment>
                <div className="d-flex justify-content-center">
                    <p>
                        Please use the form below to verify the property information we have for you, then click
                        "<span className="text-primary">Continue</span>".
                    </p>
                </div>
                <VerifyPropertyInformationForm
                    property={property}
                    handleSubmit={handleSubmit}
                    updateFormValidationIsValid={updateFormValidationIsValid}
                />
            </React.Fragment>
        );
    }
}

export default (VerifyPropertyInformation);