import {Component, ReactNode} from 'react';
import {BlobProvider} from '@react-pdf/renderer';
import ProposalPdfDocument from "./ProposalPdfDocument";
import {type Option, type Proposal, type ProposalPdf} from "@/redux/contact/types.ts";
import QuotePdfDocument from "./QuotePdfDocument";

interface OwnProps {
    onFinish? : (blob: Blob) => void;
    proposalPdf: Pick<ProposalPdf, 'signature' | 'printedName'>;
    proposal: Proposal,
    options: Array<Option>;
}

type Props = OwnProps;

class PdfRenderer extends Component<Props>
{
    public render() : ReactNode
    {
        const {onFinish, proposalPdf, proposal, options} = this.props;

        const pdfDoc = proposal.isQuote ? <QuotePdfDocument
            proposalPdf={proposalPdf}
            proposal={proposal}
            options={options}
        /> : <ProposalPdfDocument
            proposalPdf={proposalPdf}
            proposal={proposal}
            options={options}
        />;

        return (
            <BlobProvider
                document={pdfDoc}
            >
                {({blob, loading, error}) => {

                    if (error) {
                        return null;
                    }

                    if (loading || !blob) {
                        return null;
                    }

                    if (onFinish) {
                        window.requestAnimationFrame(() => onFinish(blob));
                    }

                    return null;
                }}
            </BlobProvider>
        );
    }
}

export default (PdfRenderer);
