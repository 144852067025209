import React, {Component, ReactNode} from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Logo from './branding/Logo';

class CircularLoadingIndicator extends Component
{
    public render() : ReactNode
    {
        return (
            <React.Fragment>
                <Spinner animation="grow"/>
                <Logo/>
            </React.Fragment>
        );
    }
}

export default (CircularLoadingIndicator);