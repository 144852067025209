import React, {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import {AppState} from '@/redux';
import {
    AdditionalLake,
    Contact,
    Option,
    Payment,
    Property,
    Proposal,
    ProposalCompany,
    ProposalContact,
    ProposalPdf,
    RETAIL
} from '../redux/contact/types';
import {
    getHostedToken,
    loadProposalsForContact,
    rejectLakeOrProposal,
    submitPayment,
    updateCompany,
    updateContact,
    updateLakes,
    updateOptions,
    updateProperty,
    updateProposal,
    updateProposalState
} from "../redux/contact/actions";
import Navbar from '../components/navigation/Navbar'
import FullPageLoadingIndicator from '../components/FullPageLoadingIndicator';
import ContentBase from '../components/content/ContentBase';
import {Col, Container, Row} from 'react-bootstrap';
import SystemAlert from '../components/SystemAlert';
import Footer from "../components/layout/Footer";
import ProgressBar from "../components/content/ProgressBar";
import SelectProposal from "../components/content/SelectProposal";
import VerifyContactInformation from "../components/content/VerifyContactInformation";
import VerifyPropertyInformation from "../components/content/VerifyPropertyInformation";
import VerifyLakeInformation from "../components/content/VerifyLakeInformation";
import LakeInformationRejected from "../components/content/LakeInformationRejected";
import VerifyCompanyInformation from "../components/content/VerifyCompanyInformation";
import RejectLakeInformationModal from "../components/modal/RejectLakeInformationModal";
import VerifyProposal from "../components/content/VerifyProposal";
import ProposalRejected from "../components/content/ProposalRejected";
import PaymentInformation from "../components/content/PaymentInformation";
import lakeManagementJourneyIcon from '../images/lake-management-journey-icon.png';
import RejectProposalModal from "../components/modal/RejectProposalModal";

interface StateProps {
    contact: Contact | null;
}

interface DispatchProps {
    loadProposalsForContact: (contactId: string) => void;
    updateCompany: (company: ProposalCompany, proposalId: string) => void;
    updateContact: (contact: ProposalContact, proposalId: string) => void;
    updateProperty: (property: Property, proposalId: string) => void;
    updateProposalState: (proposalState: number, proposalId: string) => void;
    updateLakes: (additionalLakes: Array<AdditionalLake>, proposalId: string) => void;
    rejectLakeOrProposal: (proposalState: number, proposalId: string, reason: string, rejectionType: string) => void;
    updateOptions: (options: Array<Option>, proposalId: string) => Promise<void | false>;
    updateProposal: (options: Array<Option>, proposalPdf: ProposalPdf, proposalId: string) => void;
    getHostedToken: (proposalId: string, frequency: string, method: string) => void;
    submitPayment: (payment: Payment, proposalId: string) => void;
}

type RouterParam = {contactId: string};

type Props = StateProps & DispatchProps & RouterParam;

interface State {
    loading: boolean;
    formIsValid: boolean;
    showSystemAlert: boolean;
    systemAlertText: string | null;
    activeProposalId: string | null;
    showRejectLakeInformationModal: boolean;
    showTooltip: boolean;
    showProposalModal: boolean;
    generateBoardPdf: boolean;
    showDigitalSignatureModal: boolean;
    showRejectProposalModal: boolean;
}

class Portal extends Component<Props, State> {
    public readonly state = {
        loading: false,
        formIsValid: true,
        showSystemAlert: false,
        systemAlertText: null,
        showMore: false,
        activeProposalId: null,
        showRejectLakeInformationModal: false,
        showTooltip: false,
        showProposalModal: false,
        generateBoardPdf: false,
        showDigitalSignatureModal: false,
        showRejectProposalModal: false
    };

    public render(): ReactNode {
        const {contact} = this.props;

        if (!contact) {
            return <FullPageLoadingIndicator/>;
        }

        let activeProposalId = this.getActiveProposalId();
        let activeProposal = contact.proposals.find((proposal : Proposal) => proposal.id === activeProposalId);

        if (!activeProposal) {
            return <SelectProposal
                handleConfirm={this.setActiveProposalId}
                rejectLakeOrProposal={this.props.rejectLakeOrProposal}
                proposals={contact.proposals}
            />;
        }

        let activeProposalState = activeProposal ? activeProposal.proposalState : 100;

        const getTitleForState: any = {
            100: 'Company Information',
            200: 'Contact Information',
            300: 'Property Information',
            400: 'Lake Information',
            500: 'Lake Information',
            600: '',
            700: 'Program Specifications',
            800: 'Payment',
            900: activeProposal.isQuote ? 'Quote Summary' : 'Proposal Summary'
        };

        const getContentForState: any = {
            100: <VerifyCompanyInformation
                company={activeProposal.proposalCompany}
                handleSubmit={this.handleCompanySubmit}
                updateFormValidationIsValid={this.updateFormValidationIsValid}
                type="company"
                />,
            200:  <VerifyContactInformation
                contact={activeProposal.proposalContact}
                handleSubmit={this.handleContactSubmit}
                updateFormValidationIsValid={this.updateFormValidationIsValid}
                type="contact"
            />,
            300: <VerifyPropertyInformation
                property={activeProposal.property}
                handleSubmit={this.handlePropertySubmit}
                updateFormValidationIsValid={this.updateFormValidationIsValid}
            />,
            400: <VerifyLakeInformation
                lakes={activeProposal.lakes}
                handleSubmit={this.handleLakeSubmit}
                updateFormValidationIsValid={this.updateFormValidationIsValid}
                additionalLakes={activeProposal.additionalLakes}
            />,
            500: <LakeInformationRejected proposalTitle={activeProposal.title} />,
            600: <VerifyProposal
                rejectLakeOrProposal={this.props.rejectLakeOrProposal}
                options={activeProposal.options}
                proposal={activeProposal}
                loading={this.state.loading}
                handleOptionChange={this.handleOptionChange}
                updateFormValidationIsValid={this.updateFormValidationIsValid}
                handleSubmit={this.handleProposalSubmit}
                handleShowProposalModal={this.handleSetShowProposalModal}
                showProposalModal={this.state.showProposalModal}
                generateBoardPdf={this.state.generateBoardPdf}
                showDigitalSignatureModal={this.state.showDigitalSignatureModal}
                handleSetShowDigitalSignatureModal={this.handleSetShowDigitalSignatureModal}
                handleSetGenerateBoardPdf={this.handleSetGenerateBoardPdf}
            />,
            700: <ProposalRejected proposalTitle={activeProposal.title} proposal={activeProposal}/>,
            800: <PaymentInformation
                getHostedToken={this.props.getHostedToken}
                proposalId={activeProposal.id}
                hostedToken={activeProposal.authHostedToken ?? null}
                paymentProfiles={activeProposal.paymentProfiles ?? []}
                paymentProfileId={activeProposal.authPaymentProfileId ?? null}
                activeProposal={activeProposal}
                updateFormValidationIsValid={this.updateFormValidationIsValid}
                handlePaymentSubmit={this.handlePaymentSubmit}
            />,
            900: <VerifyProposal
                rejectLakeOrProposal={this.props.rejectLakeOrProposal}
                options={activeProposal.options}
                proposal={activeProposal}
                loading={this.state.loading}
                handleOptionChange={this.handleOptionChange}
                updateFormValidationIsValid={this.updateFormValidationIsValid}
                handleSubmit={this.handleProposalSubmit}
                handleShowProposalModal={this.handleSetShowProposalModal}
                showProposalModal={this.state.showProposalModal}
                generateBoardPdf={this.state.generateBoardPdf}
                showDigitalSignatureModal={this.state.showDigitalSignatureModal}
                handleSetShowDigitalSignatureModal={this.handleSetShowDigitalSignatureModal}
                handleSetGenerateBoardPdf={this.handleSetGenerateBoardPdf}
                disableCheckboxes={true}
            />
        };

        return (
            <React.Fragment>
                <Navbar proposalContact={activeProposal.proposalContact} />
                <Container>
                    <Row>
                        {activeProposalState < 600 && <ProgressBar
                            proposalState={activeProposalState}
                            handleClose={this.setActiveProposalId}
                            proposal={activeProposal.pdf.proposal ? activeProposal.pdf.proposal : ''}
                            isQuote={activeProposal.isQuote}
                            jumpToEditInfo={this.jumpToEditInfo}
                            jumpBack={this.jumpBack}
                        />}
                        <Col lg={activeProposalState >= 600 ? 9 : 10} xl={activeProposalState >= 600 ? 9 : 10} className="py-2">
                            <ContentBase
                                title={getTitleForState[activeProposalState]}
                                proposalState={activeProposalState}
                                loading={this.state.loading}
                                formIsValid={this.state.formIsValid}
                                handlePrevious={this.handlePrevious}
                                handleRejectLakeInformation={this.showRejectLakeInformationModal}
                                handleRejectProposal={this.showRejectProposalModal}
                                handleDisabledButtonClick={this.handleDisabledButtonClick}
                                showTooltip={this.state.showTooltip}
                                isQuote={activeProposal.isQuote}
                                handleShowProposalModal={this.handleSetShowProposalModal}
                                isRetail={activeProposal.customerType === RETAIL}
                                handleClose={() => this.setActiveProposalId(null)}
                                setShowDigitalSignatureModal={this.handleSetShowDigitalSignatureModal}
                                hideDownloadButton={activeProposal.pdf.proposal !== undefined
                                    && activeProposal.pdf.proposal !== null
                                    && activeProposal.pdf.proposal.length > 0
                                    && activeProposal.pdf.proposal.indexOf(activeProposal.id) === -1}
                            >
                                {getContentForState[activeProposalState]}
                            </ContentBase>
                        </Col>
                        {activeProposalState >= 600 && (<Col lg={3} className="pe-0 py-0 py-lg-2 ps-0 ps-lg-4">
                            <div className="rounded shadow-sm bg-white p-4 mb-4 d-flex flex-column align-items-center">
                                <img src={lakeManagementJourneyIcon} width={125} />
                                <button
                                    className="btn btn-outline-secondary w-100 mt-4"
                                    onClick={() => this.jumpToEditInfo() }
                                >Edit Information</button>
                                <div className="d-flex flex-column align-items-end mt-3 w-100">
                                    <h3 className="text-primary">Billing Info</h3>
                                    <p className="py-0 my-0">Company ID: {activeProposal.pdf.companyId}</p>
                                    <p className="py-0 my-0">{activeProposal.proposalCompany.name}</p>
                                    <p className="py-0 my-0">{activeProposal.proposalCompany.address.address}</p>
                                    <p className="py-0 my-0">{activeProposal.proposalCompany.address.city}, {activeProposal.proposalCompany.address.state} {activeProposal.proposalCompany.address.postalCode}</p>
                                    {activeProposal && activeProposal.type === 'Quote' && (
                                        <>
                                            <p className="py-0 mt-3 mb-0">Payment Terms:</p>
                                            <p className="py-1 my-0">{activeProposal.paymentChoices.trim().replace('\r', ' or ')}</p>
                                        </>
                                    )}
                                </div>
                                {activeProposal && activeProposal.type === 'Quote' && (
                                <div className="d-flex flex-column align-items-end mt-3 w-100 text-right">
                                    <h3 className="text-primary">Shipping Info</h3>
                                    <p className="py-0 my-0">{activeProposal.shipping.name}</p>
                                    <p className="py-0 my-0">{activeProposal.shipping.address.address}</p>
                                    <p className="py-0 my-0">{activeProposal.shipping.address.city}, {activeProposal.shipping.address.state} {activeProposal.shipping.address.postalCode}</p>
                                    {activeProposal.shipping.carrier && (<p className="py-0 mt-3 mb-0">Carrier: {activeProposal.shipping.carrier}</p>)}
                                    {activeProposal.shipping.specialInstructions.length > 0 && (
                                        <>
                                            <p className="py-0 mt-3 mb-0 text-decoration-underline">Special Shipping Instructions</p>
                                            {activeProposal.shipping.specialInstructions.map((si : string) =>
                                                <p className="py-0 my-0" key={`special-instruction-${si}`}>{si}</p>
                                            )}
                                        </>
                                    )}
                                </div>
                                )}
                            </div>
                        </Col>)}
                    </Row>
                    <Footer />
                </Container>
                <SystemAlert
                    show={this.state.showSystemAlert}
                    alertText={this.state.systemAlertText}
                />
                <div className="pt-4">&nbsp;</div>
                <div className="pt-4 d-lg-none">&nbsp;</div>
                <RejectLakeInformationModal
                    show={this.state.showRejectLakeInformationModal}
                    handleClose={this.handleCloseRejectLakeInformationModal}
                    handleConfirm={this.handleConfirmRejectLakeInformationModal}
                    proposalTitle={activeProposal.title}
                    loading={this.state.loading}
                />
                <RejectProposalModal
                    show={this.state.showRejectProposalModal}
                    handleClose={this.handleCloseRejectProposalModal}
                    handleConfirm={this.handleConfirmRejectProposalModal}
                    proposalTitle={activeProposal.title}
                    loading={this.state.loading}
                    proposalType={activeProposal.type}
                />
            </React.Fragment>
        );
    }

    public async componentDidMount(): Promise<void> {
        const {contact} = this.props;
        const contactId = this.props.contactId;

        if (!contact) {
            await this.props.loadProposalsForContact(contactId);
        }

        return;
    }

    private handleCompanySubmit =
        async (company: ProposalCompany, type: string) => {
        this.setState({loading: true});

        let activeProposalId = this.getActiveProposalId();
        if (type === "company" && activeProposalId) {
            await this.props.updateCompany(company, activeProposalId);
        }

        this.setState({loading: false});
    };

    private handleContactSubmit =
        async (contact: ProposalContact, type: string) => {
            this.setState({loading: true});

            let activeProposalId = this.getActiveProposalId();

            if (type === "contact" && activeProposalId) {
                await this.props.updateContact(contact, activeProposalId);
            }

            this.setState({loading: false});
        };

    private handlePropertySubmit = async (property: Property) => {
        this.setState({loading: true});
        let activeProposalId = this.getActiveProposalId();

        if (activeProposalId) {
            await this.props.updateProperty(property, activeProposalId);
        }

        this.setState({loading: false});
    };

    private handleLakeSubmit = async (additionalLakes: Array<AdditionalLake>) => {
        this.setState({loading: true});
        let activeProposalId = this.getActiveProposalId();

        if (activeProposalId) {
            await this.props.updateLakes(additionalLakes, activeProposalId);
        }

        this.setState({loading: false});
    };

    private handleProposalSubmit = async (options: Array<Option>, proposalPdf: ProposalPdf) => {
        this.setState({loading: true});
        let activeProposalId = this.getActiveProposalId();

        if (activeProposalId) {
            await this.props.updateProposal(options, proposalPdf, activeProposalId);

            let activeProposal = this.getActiveProposal();
            if (activeProposal?.proposalState === 900) {
                await this.setActiveProposalId(null);
            }
        }

        this.setState({loading: false});
    };

    private handlePaymentSubmit = async (payment: Payment) => {
        this.setState({loading: true});
        let activeProposalId = this.getActiveProposalId();

        if (activeProposalId) {
            await this.props.submitPayment(payment, activeProposalId);
        }

        this.setState({loading: false});
    };

    private handleOptionChange = async (options: Array<Option>) => {
        this.setState({loading: true});
        let activeProposalId = this.getActiveProposalId();

        if (activeProposalId) {
            const result = await this.props.updateOptions(options, activeProposalId);

            if (result === false) {
                this.setState({
                    showSystemAlert: true,
                    systemAlertText: 'This record is locked. Please wait a few min and try again.'
                });
            }
        }

        this.setState({loading: false});
    };

    private handlePrevious = async () => {
        this.setState({loading: true});
        let contact = this.props.contact;
        if (contact) {
            let activeProposal = this.getActiveProposal();

            let previousStates: any = {
                200: 100,
                300: 200,
                400: 300,
                500: 400,
                600: 400,
                700: 600
            };

            if (activeProposal) {
                await this.props.updateProposalState(previousStates[activeProposal.proposalState], activeProposal.id);
            }
        }

        this.setState({loading: false});
    };

    private jumpToEditInfo = async () => {
        await this.jumpBack(100);
    }

    private jumpBack = async (newState : number) => {
        this.setState({loading: true});
        const activeProposal = this.getActiveProposal();

        if (activeProposal && activeProposal.proposalState < 800) {
            await this.props.updateProposalState(newState, activeProposal.id);
        }

        this.setState({loading: false});
    }

    private updateFormValidationIsValid = (isValid: boolean) => {
        this.setState({formIsValid: isValid});
    };

    private setActiveProposalId = async (proposalId: string | null): Promise<void> => {
        let proposal = this.props.contact?.proposals.find(proposal => proposal.id === proposalId) ?? null;
        if (proposalId && proposal && proposal?.proposalState < 600) {
            await this.props.updateProposalState(600, proposalId);
            proposal = this.props.contact?.proposals.find(proposal => proposal.id === proposalId) ?? null;
        }

        this.setState({activeProposalId: proposalId});
    }

    private getActiveProposalId = (): string | null => {
        return this.state.activeProposalId;
    }

    private getActiveProposal = (): Proposal | null => {
        const activeProposalId = this.getActiveProposalId();
        return this.props.contact?.proposals.find(proposal => proposal.id === activeProposalId) ?? null;
    }

    private showRejectLakeInformationModal = (): void => {
        this.setState({showRejectLakeInformationModal: true});
    }

    private handleCloseRejectLakeInformationModal = (): void => {
        this.setState({showRejectLakeInformationModal: false});
    };

    private handleConfirmRejectLakeInformationModal = async (reason: string) => {
        this.setState({loading: true});
        let activeProposalId = this.getActiveProposalId();

        if (activeProposalId) {
            await this.props.rejectLakeOrProposal(500, activeProposalId, reason, 'lakes');
        }

        this.handleCloseRejectLakeInformationModal();
        this.setState({loading: false});
    };

    private handleDisabledButtonClick = (): void => {
        this.setState({showTooltip: true});
    };

    private handleSetShowProposalModal = (showProposalModal : boolean, generateBoardPdf : boolean): void => {
        this.setState({showProposalModal: showProposalModal, generateBoardPdf: generateBoardPdf});
    };

    private handleSetGenerateBoardPdf = (generateBoardPdf : boolean): void => {
        this.setState({
            generateBoardPdf: generateBoardPdf,
            showDigitalSignatureModal: false,
        });
    };

    private handleSetShowDigitalSignatureModal = (showDigitalSignatureModal : boolean): void => {
        this.setState({showDigitalSignatureModal: showDigitalSignatureModal});
    };

    private showRejectProposalModal = (): void => {
        this.setState({showRejectProposalModal: true});
    }

    private handleCloseRejectProposalModal = (): void => {
        this.setState({showRejectProposalModal: false});
    };

    private handleConfirmRejectProposalModal = async (reason: string) => {
        this.setState({loading: true});
        let activeProposalId = this.getActiveProposalId();

        if (activeProposalId) {
            await this.props.rejectLakeOrProposal(700, activeProposalId, reason, 'proposal');
        }

        this.handleCloseRejectProposalModal();
        this.setState({loading: false});
    };
}

const mapStateToProps = (state: AppState): StateProps => ({
    contact: state.contact.contact,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, any, AnyAction>): DispatchProps => ({
    loadProposalsForContact: (contactId: string) => dispatch(loadProposalsForContact(contactId)),
    updateContact: (contact: ProposalContact, proposalId: string) => dispatch(updateContact(contact, proposalId)),
    updateCompany: (company: ProposalCompany, proposalId: string) => dispatch(updateCompany(company, proposalId)),
    updateProperty: (property: Property, proposalId: string) => dispatch(updateProperty(property, proposalId)),
    updateLakes: (additionalLakes: Array<AdditionalLake>, proposalId: string) =>
        dispatch(updateLakes(additionalLakes, proposalId)),
    updateProposalState: (proposalState: number, proposalId: string) =>
        dispatch(updateProposalState(proposalState, proposalId)),
    rejectLakeOrProposal: (proposalState: number, proposalId: string, reason: string, rejectionType: string) =>
        dispatch(rejectLakeOrProposal(proposalState, proposalId, reason, rejectionType)),
    updateOptions: (options: Array<Option>, proposalId: string) => dispatch(updateOptions(options, proposalId)),
    updateProposal: (options: Array<Option>, proposalPdf: ProposalPdf, proposalId: string) =>
        dispatch(updateProposal(options, proposalPdf, proposalId)),
    getHostedToken: (proposalId: string, frequency: string, method: string) =>
        dispatch(getHostedToken(proposalId, frequency, method)),
    submitPayment: (payment: Payment, proposalId: string) =>
        dispatch(submitPayment(payment, proposalId)),
});

export default connect<StateProps, DispatchProps, Props, AppState>(
    mapStateToProps,
    mapDispatchToProps,
)(Portal);
