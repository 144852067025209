import {Component, ReactNode} from 'react';
import {Button, Spinner, Tooltip, OverlayTrigger, Row, Col} from 'react-bootstrap';

interface OwnProps {
    title: string;
    proposalState: number;
    loading: boolean;
    formIsValid: boolean;
    handlePrevious: () => void;
    handleRejectLakeInformation: () => void;
    handleRejectProposal: () => void;
    handleDisabledButtonClick: () => void;
    handleClose: () => void;
    showTooltip: boolean;
    isQuote: boolean;
    handleShowProposalModal?: (showProposalModal : boolean, generateBoardPdf : boolean) => void;
    isRetail: boolean;
    setShowDigitalSignatureModal: (showDigitalSignatureModal: boolean) => void;
    hideDownloadButton : boolean;
    children : JSX.Element | JSX.Element[];
}

type Props = OwnProps;

class ContentBase extends Component<Props> {
    public render(): ReactNode {
        const {
            title,
            children,
            proposalState,
            loading,
            formIsValid,
            handlePrevious,
            handleRejectLakeInformation,
            handleRejectProposal,
            handleDisabledButtonClick,
            handleClose,
            showTooltip,
            isQuote,
            handleShowProposalModal,
            isRetail,
            setShowDigitalSignatureModal,
            hideDownloadButton
        } = this.props;

        let continueText = 'Continue';
        let rejectText = '';

        if (proposalState === 400) {
            continueText = 'Accept Lake Information';
            rejectText = 'Reject Lake Information';
        }

        if (proposalState === 600) {
            continueText = 'Accept ' + (isQuote ? 'Quote' : 'Proposal');
            rejectText = 'Needs Work ';
        }

        if (proposalState === 800) {
            continueText = 'Submit';
        }

        return (
            <>
                <Row className="py-3 mb-4 bg-white shadow-sm rounded">
                    <Col>
                        {isQuote && proposalState === 600 ? '' : <h3 className="text-primary">{title}</h3>}
                        {children}
                    </Col>
                </Row>
                <div className="d-flex justify-content-center mb-2 mb-lg-5 flex-column-reverse flex-md-row">
                    {!loading ? (
                        <>
                            {((!isRetail) || isRetail && [600, 800, 900].includes(proposalState)) && (
                                <Button
                                    variant="secondary"
                                    className="w-auto me-sm-3 mb-3 mb-md-0"
                                    onClick={() => handleClose()}
                                >Close {isQuote ? 'Quote': 'Proposal'}</Button>
                            )}
                            {(isRetail && proposalState < 600) && ![100, 900, 800].includes(proposalState) && (
                                <Button
                                    variant="secondary"
                                    className="w-auto me-sm-3 mb-3 mb-md-0"
                                    onClick={handlePrevious}
                                >
                                    Back
                                </Button>
                            )}
                            {([600, 900].includes(proposalState) && handleShowProposalModal && !hideDownloadButton) && (
                                <Button
                                    variant="secondary"
                                    className="w-auto me-sm-3 mb-3 mb-md-0"
                                    onClick={() => handleShowProposalModal(true, true)}
                                >
                                    Print / Download {isQuote ? 'Quote': 'Proposal'}
                                </Button>
                            )}
                            {![500, 700, 900].includes(proposalState) && (
                                <>
                                    {!formIsValid ? (
                                        <>
                                        {[400, 600].includes(proposalState) && (
                                            <Button
                                                variant="danger"
                                                size="lg"
                                                className="w-auto me-sm-3 mb-3 mb-md-0"
                                                onClick={proposalState === 400
                                                    ? handleRejectLakeInformation : handleRejectProposal}
                                            >
                                                {rejectText}
                                            </Button>
                                        )}
                                            <div
                                                onClick={() => {
                                                    if (proposalState === 600 && handleShowProposalModal) {
                                                        setShowDigitalSignatureModal(true);
                                                    } else {
                                                        handleDisabledButtonClick();
                                                    }
                                                }}
                                            >
                                        <OverlayTrigger overlay={
                                            <Tooltip id="tooltip-disabled" className="bs-tooltip-auto">
                                                {proposalState === 600 ? (
                                                    <>Please complete the Review {isQuote ? 'Quote' : 'Proposal'} steps above to accept your proposal.</>
                                                ) : (
                                                    <>Please fix the validation issues noted to continue.</>
                                                )}
                                            </Tooltip>
                                        }
                                        show={showTooltip}
                                        placement={'top'}
                                        >
                                            <Button
                                                style={{pointerEvents: 'none'}}
                                                form="mainFormContent"
                                                type="submit"
                                                variant="primary"
                                                size="lg"
                                                className="w-auto me-sm-3 mb-3 mb-md-0 me-lg-0 me-xl-3"
                                                disabled={!formIsValid && proposalState !== 600}
                                            >
                                                {continueText}
                                            </Button>
                                        </OverlayTrigger>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {[400, 600].includes(proposalState) && (
                                                <Button
                                                    variant="danger"
                                                    size="lg"
                                                    className="w-auto me-sm-3 mb-3 mb-md-0"
                                                    onClick={proposalState === 400
                                                        ? handleRejectLakeInformation : handleRejectProposal}
                                                >
                                                    {rejectText}
                                                </Button>
                                            )}
                                            <Button
                                                form="mainFormContent"
                                                type="submit"
                                                variant="primary"
                                                size="lg"
                                                disabled={!formIsValid}
                                                className="w-auto me-sm-3 mb-3 mb-md-0 me-lg-0 me-xl-3"
                                            >
                                                {continueText}
                                            </Button>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <Button
                            form="mainFormContent"
                            variant="primary"
                            size="lg"
                            type="submit"
                            className="w-auto me-sm-3 mb-3 mb-sm-0"
                            disabled
                        >
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            &nbsp; Loading
                        </Button>
                    )}
                </div>
            </>
        );
    }
}

export default (ContentBase);
