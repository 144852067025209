import {
    ACCEPT_PROPOSAL,
    ContactActionTypes,
    ContactState,
    GET_CONTACT_PROPOSALS,
    Proposal,
    SET_AUTH_HOSTED_TOKEN,
    SUBMIT_PAYMENT_INFORMATION,
    UPDATE_PROPOSAL_COMPANY,
    UPDATE_PROPOSAL_CONTACT,
    UPDATE_PROPOSAL_LAKES,
    UPDATE_PROPOSAL_OPTIONS,
    UPDATE_PROPOSAL_PROPERTY,
    UPDATE_PROPOSAL_STATE
} from './types';

const initialState : ContactState = {
    contact: null,
};

export const contactReducer = (
    state = initialState,
    action : ContactActionTypes
) : ContactState => {
    switch (action.type) {
        case GET_CONTACT_PROPOSALS:
            return {
                ...state,
                contact: action.payload.contact,
            };

        case UPDATE_PROPOSAL_LAKES:
            if (state.contact === null) {
                return state;
            }

            return {
                ...state,
                contact: {
                    ...state.contact,
                    proposals: state.contact.proposals.map(
                        (proposal: Proposal) => proposal.id === action.payload.proposalId
                            ? {
                                ...proposal,
                                proposalState: action.payload.proposalState,
                                additionalLakes: action.payload.additionalLakes
                            } : proposal
                    )
                },
            };

        case UPDATE_PROPOSAL_OPTIONS:
            if (state.contact === null) {
                return state;
            }

            return {
                ...state,
                contact: {
                    ...state.contact,
                    proposals: state.contact.proposals.map(
                        (proposal: Proposal) => proposal.id === action.payload.proposalId
                            ? {
                                ...proposal,
                                totalFull: action.payload.totalFull,
                                totalMonthly: action.payload.totalMonthly,
                                optionTotalUSD: action.payload.optionTotalUSD,
                                proposalTotal: action.payload.proposalTotal,
                                subtotal: action.payload.subtotal,
                                taxRate: action.payload.taxRate,
                                options: action.payload.options,
                                quoteTax: action.payload.quoteTax.toString(),
                            } : proposal
                    )
                },
            };

        case ACCEPT_PROPOSAL:
            if (state.contact === null) {
                return state;
            }

            return {
                ...state,
                contact: {
                    ...state.contact,
                    proposals: state.contact.proposals.map(
                        (proposal: Proposal) => proposal.id === action.payload.proposalId
                            ? {
                                ...proposal,
                                proposalState: action.payload.proposalState,
                                options: action.payload.options,
                                pdf: {
                                    ...proposal.pdf,
                                    proposal: action.payload.proposal
                                }
                            } : proposal
                    )
                },
            };

        case SUBMIT_PAYMENT_INFORMATION:
            if (state.contact === null) {
                return state;
            }

            return {
                ...state,
                contact: {
                    ...state.contact,
                    proposals: state.contact.proposals.map(
                        (proposal: Proposal) => proposal.id === action.payload.proposalId
                            ? {
                                ...proposal,
                                proposalState: action.payload.proposalState,
                                payment: action.payload.payment
                            } : proposal
                    )
                },
            };

        case UPDATE_PROPOSAL_COMPANY:
            if (state.contact === null) {
                return state;
            }

            return {
                ...state,
                contact: {
                    ...state.contact,
                    proposals: state.contact.proposals.map(
                        (proposal: Proposal) => proposal.id === action.payload.proposalId
                            ? {
                                ...proposal,
                                proposalState: action.payload.proposalState,
                                proposalCompany: action.payload.proposalCompany
                            } : proposal
                    )
                },
            };

        case UPDATE_PROPOSAL_CONTACT:
            if (state.contact === null) {
                return state;
            }

            return {
                ...state,
                contact: {
                    ...state.contact,
                    proposals: state.contact.proposals.map(
                        (proposal: Proposal) => proposal.id === action.payload.proposalId
                            ? {
                                ...proposal,
                                proposalState: action.payload.proposalState,
                                proposalContact: action.payload.proposalContact,
                                proposalCompany: action.payload.proposalCompany ?
                                    action.payload.proposalCompany : {...proposal.proposalCompany},
                                nameMatch: false
                            } : proposal
                    )
                },
            };

        case UPDATE_PROPOSAL_PROPERTY:
            if (state.contact === null) {
                return state;
            }

            return {
                ...state,
                contact: {
                    ...state.contact,
                    proposals: state.contact.proposals.map(
                        (proposal: Proposal) => proposal.id === action.payload.proposalId
                            ? {
                                ...proposal,
                                proposalState: action.payload.proposalState,
                                property: action.payload.property
                            } : proposal
                    )
                },
            };

        case UPDATE_PROPOSAL_STATE:
            if (state.contact === null) {
                return state;
            }

            return {
                ...state,
                contact: {
                    ...state.contact,
                    proposals: state.contact.proposals.map(
                        (proposal: Proposal) => proposal.id === action.payload.proposalId
                            ? {
                                ...proposal,
                                proposalState: action.payload.proposalState,
                            } : proposal
                    )
                },
            };

        case SET_AUTH_HOSTED_TOKEN:
            if (state.contact === null) {
                return state;
            }

            return {
                ...state,
                contact: {
                    ...state.contact,
                    proposals: state.contact.proposals.map(
                        (proposal: Proposal) => proposal.id === action.payload.proposalId
                            ? {
                                ...proposal,
                                authHostedToken: action.payload.authHostedToken,
                                paymentProfiles: action.payload.paymentProfiles,
                            } : proposal
                    )
                },
            };

        default:
            return state;
    }
};