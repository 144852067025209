import { push } from "redux-first-history";
import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {apiEndpoint, apiFetch} from '@/utils/api.js';
import {AppState} from '@/redux/index.js';
import {
    ContactActionTypes,
    GET_CONTACT_PROPOSALS,
    Contact,
    ProposalCompany,
    ProposalContact,
    UPDATE_PROPOSAL_COMPANY,
    UPDATE_PROPOSAL_CONTACT,
    Property,
    UPDATE_PROPOSAL_PROPERTY,
    UPDATE_PROPOSAL_STATE,
    AdditionalLake,
    UPDATE_PROPOSAL_LAKES,
    UPDATE_PROPOSAL_OPTIONS, Option, ProposalPdf,
    ACCEPT_PROPOSAL,
    SET_AUTH_HOSTED_TOKEN, Payment, SUBMIT_PAYMENT_INFORMATION
} from './types';

const setContact = (contact : Contact) : ContactActionTypes => {
    return {
        type: GET_CONTACT_PROPOSALS,
        payload: {contact},
    };
};

export const updateCompany = (
    proposalCompany: ProposalCompany,
    proposalId: string,
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async (dispatch, getState) => {
    const contact = getState().contact.contact;
    const result = await apiFetch(new URL(`${apiEndpoint}/proposal/company/${proposalId}`).href, contact, {
        method: 'PUT',
        body: JSON.stringify(proposalCompany),
    });

    let newState = 200;

    if (!result.ok) {
        switch (result.status) {
            case 409:
                // Result returned invalid form data, keep the state the same and allow validation to trigger
                newState = 100;
                break;

            default:
                dispatch(push('/'));
                return;
        }
    }

    dispatch({
        type: UPDATE_PROPOSAL_COMPANY,
        payload: {proposalState: newState, proposalCompany: proposalCompany, proposalId: proposalId}
    });
};

export const updateContact = (
    proposalContact: ProposalContact,
    proposalId: string,
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async (dispatch, getState) => {
    const contact = getState().contact.contact;
    let newState = 300;
    let nameMatch = false;

    // Run update company if we skipped company because of name match
    if (contact) {
        let currProposal = contact.proposals.find(proposal => proposal.id === proposalId);
        if (currProposal !== undefined && currProposal.nameMatch) {
            nameMatch = true;
            const companyResult = await apiFetch(new URL(
                `${apiEndpoint}/proposal/company/${proposalId}`).href,
                contact,
                {
                    method: 'PUT',
                    body: JSON.stringify(proposalContact),
                }
            );

            if (!companyResult.ok) {
                switch (companyResult.status) {
                    case 409:
                        newState = 200;
                        break;

                    default:
                        dispatch(push('/'));
                        return;
                }
            }
        }
    }

    const result = await apiFetch(new URL(`${apiEndpoint}/proposal/contact/${proposalId}`).href, contact, {
        method: 'PUT',
        body: JSON.stringify(proposalContact),
    });

    if (!result.ok) {
        switch (result.status) {
            case 409:
                // Result returned invalid form data, keep the state the same and allow validation to trigger
                newState = 200;
                break;

            default:
                dispatch(push('/'));
                return;
        }
    }

    dispatch({
        type: UPDATE_PROPOSAL_CONTACT,
        payload: {
            proposalState: newState,
            proposalContact: proposalContact,
            proposalId: proposalId,
            proposalCompany: nameMatch ? proposalContact : null
        }
    });
};

export const updateProperty = (
    property: Property,
    proposalId: string,
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async (dispatch, getState) => {
    const contact = getState().contact.contact;
    const result = await apiFetch(new URL(`${apiEndpoint}/proposal/property/${proposalId}`).href, contact, {
        method: 'PUT',
        body: JSON.stringify(property)
    });

    let newState = 400;


    if (!result.ok) {
        switch (result.status) {
            case 409:
                // Result returned invalid form data, keep the state the same and allow validation to trigger
                newState = 300;
                break;

            default:
                dispatch(push('/'));
                return;
        }
    }

    dispatch({
        type: UPDATE_PROPOSAL_PROPERTY,
        payload: {proposalState: newState, property: property, proposalId: proposalId}
    });
};

export const updateProposal = (
    options: Array<Option>,
    proposalPdf: ProposalPdf,
    proposalId: string
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async (dispatch, getState) => {
    const contact = getState().contact.contact;
    contact?.proposals.find(proposal => proposal.id === proposalId);

    let newState = 800;

    const activeProposal = contact?.proposals.find(proposal => proposal.id === proposalId);
    if (activeProposal?.isQuote && !activeProposal.paymentChoices.includes('Prepayment')) {
        newState = 900;
    }

    const result = await apiFetch(new URL(`${apiEndpoint}/proposal/accept/${proposalId}`).href, contact, {
        method: 'PUT',
        body: JSON.stringify({
            ...proposalPdf,
            proposalState: newState
        })
    });

    if (!result.ok) {
        switch (result.status) {
            case 409:
                // Result returned invalid form data, keep the state the same and allow validation to trigger
                newState = 600;
                break;

            default:
                dispatch(push('/'));
                return;
        }
    }

    const updateProposalResult = await result.json();
    const proposal = updateProposalResult.proposal;

    dispatch({
        type: ACCEPT_PROPOSAL,
        payload: {proposalState: newState, options: options, proposal: proposal, proposalId: proposalId}
    });
};

export const submitPayment = (
    payment: Payment,
    proposalId: string
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async (dispatch, getState) => {
    const contact = getState().contact.contact;
    const result = await apiFetch(new URL(`${apiEndpoint}/proposal/payment/${proposalId}`).href, contact, {
        method: 'PUT',
        body: JSON.stringify(payment)
    });

    let newState = 900;

    if (!result.ok) {
        switch (result.status) {
            case 409:
                // Result returned invalid form data, keep the state the same and allow validation to trigger
                newState = 800;
                break;

            default:
                dispatch(push('/'));
                return;
        }
    }

    dispatch({
        type: SUBMIT_PAYMENT_INFORMATION,
        payload: {proposalState: newState, payment: payment, proposalId: proposalId}
    });
};

export const getHostedToken = (proposalId: string, frequency: string, method: string = 'credit') : ThunkAction<Promise<void>, AppState, null, Action<string>> => async (dispatch, getState) => {
    const contact = getState().contact.contact;
    const result = await apiFetch(new URL(`${apiEndpoint}/authnet/hosted-token/${proposalId}/${method}/${frequency}`).href, contact);

    if (!result.ok) {
        dispatch(push('/'));
        return;
    }

    const authNetResult = await result.json();

    dispatch({
        type: SET_AUTH_HOSTED_TOKEN,
        payload: {
            authHostedToken: authNetResult.token,
            paymentProfiles: authNetResult.paymentProfiles,
            proposalId: proposalId
        }
    });
};

export const updateLakes = (
    additionalLakes: Array<AdditionalLake>,
    proposalId: string,
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async (dispatch, getState) => {
    const contact = getState().contact.contact;
    const result = await apiFetch(new URL(`${apiEndpoint}/proposal/lakes/${proposalId}`).href, contact, {
        method: 'POST',
        body: JSON.stringify(additionalLakes)
    });

    let newState = 600;


    if (!result.ok) {
        switch (result.status) {
            case 409:
                // Result returned invalid form data, keep the state the same and allow validation to trigger
                newState = 400;
                break;

            default:
                dispatch(push('/'));
                return;
        }
    }

    dispatch({
        type: UPDATE_PROPOSAL_LAKES,
        payload: {proposalState: newState, additionalLakes: additionalLakes, proposalId: proposalId}
    });
};

export const updateOptions = (
    options: Array<Option>,
    proposalId: string,
) : ThunkAction<Promise<void | false>, AppState, null, Action<string>> => async (dispatch, getState) => {
    const contact = getState().contact.contact;
    const result = await apiFetch(new URL(`${apiEndpoint}/proposal/calculate-total/${proposalId}`).href, contact, {
        method: 'PUT',
        body: JSON.stringify(options)
    });

    if (!result.ok) {
        try {
            const json = await result.json();

            if (json.error === 'Record Locked') {
                return false;
            }
        } catch (e: unknown) {
            // fall through
        }

        dispatch(push('/'));

        return;
    }

    const calculateTotalResult = await result.json();

    dispatch({
        type: UPDATE_PROPOSAL_OPTIONS,
        payload: {
            proposalId: proposalId,
            options: options,
            totalFull: calculateTotalResult.totalFull,
            totalMonthly: calculateTotalResult.totalMonthly,
            optionTotalUSD: calculateTotalResult.optionTotalUSD,
            proposalTotal: calculateTotalResult.proposalTotal,
            subtotal: calculateTotalResult.subtotal,
            taxRate: calculateTotalResult.taxRate,
            quoteTax: calculateTotalResult.quoteTax,
        }
    });
};

export const updateProposalState = (
    proposalState: number,
    proposalId: string,
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async (dispatch, getState) => {
    const contact = getState().contact.contact;
    const result = await apiFetch(new URL(`${apiEndpoint}/proposal/state/${proposalId}`).href, contact, {
        method: 'PUT',
        body: JSON.stringify({proposalState: proposalState})
    });

    if (!result.ok) {
        switch (result.status) {
            default:
                dispatch(push('/'));
                return;
        }
    }

    dispatch({
        type: UPDATE_PROPOSAL_STATE,
        payload: {proposalState: proposalState, proposalId: proposalId}
    });
};

export const rejectLakeOrProposal = (
    proposalState: number,
    proposalId: string,
    reason: string,
    rejectionType: string
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async (dispatch, getState) => {
    const contact = getState().contact.contact;
    const result = await apiFetch(new URL(`${apiEndpoint}/proposal/${rejectionType}/reject/${proposalId}`).href, contact, {
        method: 'PUT',
        body: JSON.stringify({reason: reason})
    });

    if (!result.ok) {
        switch (result.status) {
            default:
                dispatch(push('/'));
                return;
        }
    }

    dispatch({
        type: UPDATE_PROPOSAL_STATE,
        payload: {proposalState: proposalState, proposalId: proposalId}
    });
};

export const loadProposalsForContact = (
    contactId: string
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async dispatch => {

    const contactResult = await apiFetch(new URL(`${apiEndpoint}/proposals/${contactId}`).href, null);

    if (!contactResult.ok) {
        dispatch(push('/'));
        return;
    }

    const contact = await contactResult.json();
    dispatch(setContact(contact));
};
