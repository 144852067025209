import React, {Component, ReactNode} from 'react';
import {Option, Proposal, ProposalPdf, QuoteProduct, RETAIL} from "../../redux/contact/types";
import FormGroup from "../forms/FormGroup";
import DigitalSignatureModal from "../modal/DigitalSignatureModal";
import TermsModal from "../modal/TermsModal";
import ProposalModal from "../modal/PropoalModal";
import ProposalTotal from "./ProposalTotal";
import VerifyProposalForm from "../forms/VerifyProposalForm";
import PdfRenderer from "../pdf/PdfRenderer";
import {Spinner} from "react-bootstrap";
import {formatPrice} from "../../utils/formatPrice";
import moment from 'moment';
import {Jumbotron} from "../Jumbotron";

interface OwnProps {
    options: Array<Option>;
    proposal: Proposal;
    loading: boolean;
    handleOptionChange: (options: Array<Option>) => Promise<void>;
    updateFormValidationIsValid : (isValid : boolean) => void;
    handleSubmit : (options: Array<Option>, proposalPdf: ProposalPdf) => Promise<void>;
    handleShowProposalModal : (showProposalModal : boolean, generateBoardPdf : boolean) => void;
    showProposalModal: boolean;
    generateBoardPdf: boolean;
    showDigitalSignatureModal: boolean;
    handleSetShowDigitalSignatureModal: (showDigitalSignatureModal : boolean) => void;
    handleSetGenerateBoardPdf: (generateBoardPdf : boolean) => void;
    rejectLakeOrProposal: (proposalState: number, proposalId: string, reason: string, rejectionType: string) => void;
    disableCheckboxes?: boolean;
}

export interface ProposalVerificationState {
    signature: string | null;
    printedName: string | null;
    terms: boolean;
    pdfReviewed: boolean;
}

interface State {
    options: Array<Option>;
    showTermsModal: boolean;
    showRejectProposalModal: boolean;
    proposalVerificationState: ProposalVerificationState;
    reviewComplete: boolean;
    pdfDocument: any;
}

type Props = OwnProps;

class VerifyProposal extends Component<Props, State>
{
    public readonly state = {
        options: this.props.options,
        showTermsModal: false,
        proposalVerificationState: { signature: null, printedName: null, terms: false, pdfReviewed: false },
        reviewComplete: false,
        pdfDocument: undefined,
        showRejectProposalModal: false,
    };

    public async componentDidMount(): Promise<void> {
        this.updateReviewStatus();
    }

    public render() : ReactNode
    {
        const {proposal, loading, handleSubmit, showProposalModal, generateBoardPdf, showDigitalSignatureModal, disableCheckboxes} = this.props;
        const {pdfDocument} = this.state;

        return (
            <React.Fragment>
                <div className="d-flex justify-content-center flex-column">
                    {!proposal.isQuote ? (
                        <>
                            <h2 className="text-primary">{proposal.sectionTitle}</h2>
                            <h3>Proposal #: {proposal.title}</h3>
                            <h3 className="text-primary mt-2">Program Specifications</h3>
                            <p>{proposal.pdf.programSpecifications}</p>
                            {proposal.lineItemOptions !== '' && (
                                <p>{proposal.lineItemOptions}</p>
                            )}
                            {proposal.pdf.notesAndPrecautions !== '' && (
                                <>
                                    <h3 className="text-primary">Notes &amp; Precautions</h3>
                                    <p>{proposal.pdf.notesAndPrecautions}</p>
                                </>
                            )}
                            <h3 className="text-primary">Click Below To Add Additional Options/Services To Your Program</h3>
                            <table className="table-sm table-bordered w-100 options-table">
                                <thead className="bg-primary text-white">
                                <tr>
                                    <th>Add</th>
                                    <th>Option / Description</th>
                                    <th>Price</th>
                                </tr>
                                </thead>
                                <tbody className="text-secondary">
                                {this.state.options.map( (option : Option) => (
                                    <tr key={option.id}>
                                        <td className="optionCheckColumn">
                                            {disableCheckboxes ? (option.state ? (<div className="ml-2"> &#10003;</div>) : (<></>)) : (
                                                <FormGroup
                                                    name={`option[${option.id}]`}
                                                    type="checkbox"
                                                    value="1"
                                                    handleChange={() => this.handleOptionClick(option.id)}
                                                    checked={option.state}
                                                />
                                            )}
                                        </td>
                                        <td>{option.description}</td>
                                        <td className="text-right">{option.priceUSD}</td>
                                    </tr>
                                ))}
                                {this.state.options.length < 1 && (
                                    <tr>
                                        <td colSpan={3}>
                                            <div className="d-flex justify-content-center">No Options Available</div>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            <div className="row justify-content-end d-flex flex-nowrap">
                                <div className="col-6  mt-3 ">
                                    {!proposal.isQuote && proposal.isPrior && proposal.discountPercent && (
                                        <p>* The Proposal Total will be reduced by {proposal.discountPercent}% if full payment is received
                                            on or before {moment(proposal.priorTo).format('MM-DD-YYYY')}.
                                            The Discounted Proposal Total Amount is {formatPrice(proposal.totalFull + "", 2)}.</p>
                                    )}
                                </div>
                                <div className="col-6 d-flex justify-content-end ">
                                    <Jumbotron className="d-flex flex-column align-items-end p-2 mt-3 ps-3 pe-3">
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td><p>Program Price:</p></td>
                                                <td className="totalColumn">
                                                    <ProposalTotal value={formatPrice(proposal.subtotal, 2)} loading={loading}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><p>Optional Treatments / Services:</p></td>
                                                <td className="totalColumn">
                                                    <ProposalTotal value={formatPrice(proposal.optionTotalUSD, 2)} loading={loading}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><p>Tax:</p></td>
                                                <td className="totalColumn">
                                                    <ProposalTotal value={'$' + formatPrice(proposal.quoteTax, 2)} loading={loading}/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <hr className="m-0 p-0 w-100 mb-3" />
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td><p className="text-primary">Proposal Total:</p></td>
                                                <td className="totalColumn">
                                                    <ProposalTotal
                                                        value={formatPrice(proposal.proposalTotal, 2)}
                                                        loading={loading}
                                                        text="text-primary"
                                                    />
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </Jumbotron>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                        <h2 className="text-primary">{proposal.sectionTitle} / {proposal.property.name}</h2>
                        <h3>Quote #: {proposal.title}</h3>
                        <h3>Customer PO#: {proposal.shipping.poNumber}</h3>
                        <table className="table-sm table-bordered w-100 options-table">
                            <thead className="bg-primary text-white">
                            <tr>
                                <th>Product ID</th>
                                <th>Description</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>UOM</th>
                                <th>Ext. Price</th>
                            </tr>
                            </thead>
                            <tbody className="text-secondary">
                            {proposal.products && proposal.products.map( (product : QuoteProduct) => {
                                return (
                                    <tr key={product.productId}>
                                        <td>{product.productId}</td>
                                        <td>{product.description}</td>
                                        <td className="text-right">{formatPrice(product.price)}</td>
                                        <td className="text-right">{product.quantity}</td>
                                        <td>{product.uom}</td>
                                        <td className="text-right">{formatPrice(product.extendedPrice)}</td>
                                    </tr>
                                )
                            })}
                            {proposal.products && proposal.products.length < 1 && (
                                <tr>
                                    <td colSpan={3}>
                                        <div className="d-flex justify-content-center">No Products Available</div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <div className="d-flex flex-row justify-content-end">
                            <Jumbotron className="d-flex flex-column align-items-end p-2 mt-3 ps-3 pe-3">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><p>Subtotal:</p></td>
                                            <td className="totalColumn">
                                                <ProposalTotal value={'$' + formatPrice(proposal.quoteSubtotal, 2)} loading={loading}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p>Shipping Cost:</p></td>
                                            <td className="totalColumn">
                                                <ProposalTotal value={'$' + formatPrice(proposal.shippingCost, 2)} loading={loading}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p>Tax:</p></td>
                                            <td className="totalColumn">
                                                <ProposalTotal value={'$' + formatPrice(proposal.quoteTax, 2)} loading={loading}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr className="m-0 p-0 w-100 mb-3" />
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><p className="text-primary">Grand Total:</p></td>
                                            <td className="totalColumn">
                                                <ProposalTotal
                                                    value={formatPrice(proposal.proposalTotal, 2)}
                                                    loading={loading}
                                                    text="text-primary"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Jumbotron>
                        </div>
                        </>
                    )}
                    {!proposal.isQuote && this.state.options.length > 0 && !disableCheckboxes && (
                        <>
                            <h3 className="text-primary">Review Proposal</h3>
                            <p>
                                Review the list of optional treatments and services above. Use the checkbox to the left of
                                the treatment / service option above to add an item to your proposal. Then click
                                "<span className="text-primary">Accept Proposal</span>".
                            </p>
                        </>
                    )}
                    <DigitalSignatureModal
                        show={showDigitalSignatureModal}
                        handleClose={this.handleCloseDigitalSignatureModal}
                        handleConfirm={this.handleConfirmDigitalSignatureModal}
                        isQuote={proposal.isQuote}
                        company={proposal.proposalCompany.name}
                        terms={proposal.terms}
                    />
                    <TermsModal
                        show={this.state.showTermsModal}
                        handleClose={this.handleCloseTermsModal}
                        handleConfirm={this.handleConfirmTermsModal}
                        company={proposal.proposalCompany.name}
                        terms={proposal.terms}
                    />
                    <ProposalModal
                        show={showProposalModal}
                        handleClose={this.handleCloseProposalModal}
                        handleConfirm={this.handleConfirmProposalModal}
                        rejectLakeOrProposal={this.props.rejectLakeOrProposal}
                        pdfDocument={pdfDocument}
                        proposalTitle={proposal.title}
                        isQuote={proposal.isQuote}
                    >
                        {pdfDocument !== undefined ? (
                            <React.Fragment>
                                <iframe
                                    src={pdfDocument}
                                    frameBorder={0}
                                    width={'100%'}
                                    height={'500px;'}
                                    className="bg-white mt-0"
                                    title="pdf-iframe"
                                />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                &nbsp; Loading
                            </React.Fragment>
                        )}
                    </ProposalModal>
                    <VerifyProposalForm
                        handleSubmit={handleSubmit}
                        options={this.state.options}
                        proposalPdf={{
                            signature: this.state.proposalVerificationState.signature,
                            printedName: this.state.proposalVerificationState.printedName,
                            proposal: this.state.pdfDocument
                        }}
                    />
                    {generateBoardPdf && (
                        <React.Suspense fallback={<div/>}>
                            <PdfRenderer
                                proposalPdf={this.state.proposalVerificationState}
                                proposal={proposal}
                                onFinish={this.handleBoardPdfEnd}
                                options={this.state.options}
                            />
                        </React.Suspense>
                    )}
                </div>
            </React.Fragment>
        );
    }

    private handleOptionClick = async (optionId: string) => {
        const {handleOptionChange} = this.props;
        let options = this.state.options.map(
            (option: Option) => option.id === optionId
                ? {
                    ...option,
                    state: !option.state,
                } : option);
        await this.setState({options: options});
        await handleOptionChange(this.state.options);
    };

    private handleCloseDigitalSignatureModal = (): void => {
        const {handleSetShowDigitalSignatureModal} = this.props;
        handleSetShowDigitalSignatureModal(false);
    };

    private handleCloseTermsModal = (): void => {
        this.setState({showTermsModal: false});
    };

    private handleCloseProposalModal = (): void => {
        const {handleShowProposalModal} = this.props;
        handleShowProposalModal(false, true);
    };

    private handleConfirmProposalModal = async ()  => {
        const {handleShowProposalModal, handleSetShowDigitalSignatureModal} = this.props;

        handleShowProposalModal(false, false);

        this.setState({
            proposalVerificationState: {
                ...this.state.proposalVerificationState,
                pdfReviewed: true,
                terms: true
            }
        });
        this.updateReviewStatus();

        handleSetShowDigitalSignatureModal(true);
    };

    private handleConfirmTermsModal = async () => {
        this.setState({showTermsModal: false});
        await this.setState(
            {proposalVerificationState: {...this.state.proposalVerificationState, terms: true}}
        );
        this.updateReviewStatus();
    };

    private handleConfirmDigitalSignatureModal = async (signature: string, printedName: string) => {
        const {handleSetGenerateBoardPdf, handleSetShowDigitalSignatureModal} = this.props;
        this.setState(
            {
                proposalVerificationState: {
                    ...this.state.proposalVerificationState,
                    signature: signature,
                    printedName: printedName,
                    pdfReviewed: true,
                    terms: true
                }
            }
        );
        handleSetGenerateBoardPdf(true);
        handleSetShowDigitalSignatureModal(false);
    };

    private updateReviewStatus = (): boolean => {
        const { updateFormValidationIsValid } = this.props;
        let status = true;

        if (!this.state.proposalVerificationState.pdfReviewed
            && this.props.proposal?.customerType === RETAIL) {
            status = false;
        }

        if (!this.state.proposalVerificationState.signature) {
            status = false;
        }

        if (!this.state.proposalVerificationState.terms
            && this.props.proposal?.customerType === RETAIL) {
            status = false;
        }

        this.setState( {reviewComplete: status});
        updateFormValidationIsValid(status);
        return status;
    }

    private handleBoardPdfEnd = async (blob: any) : Promise<void> => {
        const {handleSubmit, handleSetGenerateBoardPdf, proposal} = this.props;

        //if we are done, and we have the rendered pdf in s3 show that.
        if (proposal.proposalState === 900 && proposal.pdf.proposal) {
            handleSetGenerateBoardPdf(false);
            this.setState({pdfDocument: proposal.pdf.proposal});
            this.updateReviewStatus();
            return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
            const base64data = reader.result!.toString();
            handleSetGenerateBoardPdf(false);
            this.setState({pdfDocument: base64data});

            if (this.updateReviewStatus()) {
                await handleSubmit(
                    this.state.options,
                    {
                        signature: this.state.proposalVerificationState.signature,
                        printedName: this.state.proposalVerificationState.printedName,
                        proposal: base64data
                    }
                );
            }
        };
    };
}

export default (VerifyProposal);
