import {Component, createRef, ReactNode} from "react";
import {Formik} from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import FormGroup from './FormGroup';

interface OwnProps
{
    handleSubmit : (printedName: string) => void;
}

interface Field
{
    label : string;
    name : string;
    type : string;
    disabled : boolean;
}

interface NameToValueMap
{
    [key: string]: any;
}

type Props = OwnProps;

class PrintedNameForm extends Component<Props>
{
    public form : any;

    constructor(props : Props) {
        super(props);
        this.form = createRef();
    }

    public render() : ReactNode
    {
        const schema = yup.object({
            printedName: yup.string().required('You must type your name above')
        });

        const printedNameFieldset : Array<Field> = [
            {label : 'Printed Name', name : 'printedName', type : 'text', disabled: false},
        ];

        const initialValues : NameToValueMap = {
            printedName: '',
        };

        return (
            <Formik
                onSubmit={this.handleSubmit}
                initialValues={initialValues}
                validationSchema={schema}
                innerRef={this.form}
            >
                {({
                      handleSubmit,
                      values,
                      errors
                  }) => (
                    <form noValidate onSubmit={handleSubmit} id="printedNameForm" autoComplete="off">
                        <Form.Group>
                            <div className="d-flex w-100 flex-column">
                                {printedNameFieldset.map( (field : Field) => (
                                    <FormGroup
                                        key={field.name}
                                        label={field.label}
                                        name={field.name}
                                        type={field.type}
                                        value={values[field.name]}
                                        handleChange={this.handleChange}
                                        errors={errors[field.name]}
                                        md={8}
                                        disabled={field.disabled}
                                    />
                                ))}
                            </div>
                        </Form.Group>
                    </form>
                )}
            </Formik>
        );
    }

    private handleChange = async (e: React.FormEvent<HTMLFormElement> | undefined) => {
        await this.form.current.handleChange(e);
        await this.form.current.validateForm();
    };

    private handleSubmit = async (values : any) => {
        const {handleSubmit} = this.props;
        await handleSubmit(values.printedName);
    };
}

export default (PrintedNameForm);
