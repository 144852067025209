import {Route, Routes, useParams} from 'react-router-dom';
import Portal from './pages/Portal';
import NotFound404 from './pages/NotFound404'

const App = () =>{
    return (
        <>
            <Routes>
                <Route path="/contact/" element={<NotFound404/>}/>
                <Route path="/contact/:contactId" element={<PortalLoader/>}/>
                <Route path="*" element={<NotFound404/>}/>
            </Routes>
        </>
    );
}

const PortalLoader = () => {
    const {contactId} = useParams();

    if (!contactId) {
        return <NotFound404/>
    }

    // @ts-expect-error
    return <Portal contactId={contactId}/>
}

export default App;
