import {Component, createRef, ReactNode} from 'react';
import {Formik} from 'formik';
import * as yup from 'yup';
import Form from "react-bootstrap/Form";
import {phoneRegExp, zipCode} from '@/utils/regex.ts';
import FormGroup from './FormGroup';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import {ProposalContact} from "@/redux/contact/types.ts";

interface OwnProps
{
    contact : ProposalContact;
    type: string;
    handleSubmit : (contact : ProposalContact, type: string) => Promise<void>;
    updateFormValidationIsValid : (isValid : boolean) => void;
}

interface NameToValueMap
{
    [key: string]: any;
}

type Props = OwnProps;

class VerifyContactInformationForm extends Component<Props>
{
    public form : any;

    constructor(props : Props) {
        super(props);
        this.form = createRef();
    }

    async componentDidMount() {
        const {updateFormValidationIsValid} = this.props;
        const errors = await this.form.current.validateForm();
        updateFormValidationIsValid(Object.keys(errors).length === 0);

        if (this.form.current) {
            this.form.current.resetForm();
        }
    }

    public render() : ReactNode
    {
        const {contact, type} = this.props;

        const schema = yup.object({
            name: yup.string().required(),
            address: yup.string().required(),
            city: yup.string().required(),
            state: yup.string().required(),
            postalCode: yup.string().matches(
                zipCode,
                'A valid Zip / Postal Code is required'
            ).required(),
            email: yup.string().email().required(),
            phone: yup.string().matches(
                phoneRegExp,
                'Phone number is not valid. Format 555-555-5555.'
            ).required(),
        });

        const initialValues : NameToValueMap = {
            address: contact.address.address,
            city: contact.address.city,
            state: contact.address.state,
            postalCode: contact.address.postalCode,
            email: contact.email,
            phone: contact.phone,
            name: contact.name,
        };

        return (
            <Formik
                onSubmit={this.handleSubmit}
                initialValues={initialValues}
                validationSchema={schema}
                innerRef={this.form}
            >
                {({
                      handleSubmit,
                      values,
                      errors
                  }) => (
                      <>
                        <form noValidate onSubmit={handleSubmit} id="mainFormContent" name={type}>
                            <Form.Group>
                                <div className="d-flex flex-row w-100">
                                    <FormGroup
                                        label="Contact name"
                                        name="name"
                                        type="text"
                                        value={values['name']}
                                        handleChange={this.handleChange}
                                        errors={errors['name']}
                                    />
                                    <FormGroup
                                        label="Contact phone"
                                        name="phone"
                                        type="text"
                                        value={values['phone']}
                                        handleChange={this.handleChange}
                                        errors={errors['phone']}
                                        placeholder="Ex. (555) 555-5555"
                                    />
                                </div>
                                <div className="d-flex flex-row w-50">
                                    <FormGroup
                                        label="Contact email"
                                        name="email"
                                        type="text"
                                        value={values['email']}
                                        handleChange={this.handleChange}
                                        errors={errors['email']}
                                    />
                                </div>
                            </Form.Group>
                            <div className="d-flex flex-column w-100">
                                <hr className="w-100" />
                                <h3 className="text-primary">Bill To</h3>
                            </div>
                            <Form.Group>
                                <div className="d-flex flex-row w-100">
                                    <FormGroup
                                        label="Address"
                                        name="address"
                                        type="text"
                                        value={values['address']}
                                        handleChange={this.handleChange}
                                        errors={errors['address']}
                                    />
                                    <FormGroup
                                        label="City"
                                        name="city"
                                        type="text"
                                        value={values['city']}
                                        handleChange={this.handleChange}
                                        errors={errors['city']}
                                    />
                                </div>
                                <div className="d-flex flex-row w-100">
                                    <FormGroup
                                        label="State"
                                        name="state"
                                        type="text"
                                        value={values['state']}
                                        handleChange={this.handleChange}
                                        errors={errors['state']}
                                    />
                                    <FormGroup
                                        label="Zip / Postal Code"
                                        name="postalCode"
                                        type="text"
                                        value={values['postalCode']}
                                        handleChange={this.handleChange}
                                        errors={errors['postalCode']}
                                    />
                                </div>
                            </Form.Group>
                        </form>
                      </>
                )}
            </Formik>
        );
    }

    private handleChange = async (e: React.FormEvent<HTMLFormElement> | undefined) => {
        await this.form.current.handleChange(e);
        const {updateFormValidationIsValid} = this.props;
        const errors = await this.form.current.validateForm();
        updateFormValidationIsValid(Object.keys(errors).length === 0);
    };

    private handleSubmit = async (values : any) => {

        const {handleSubmit, type} = this.props;
        let phoneNumber = '';

        if (values.phone) {
            const parsedPhoneNumber = parsePhoneNumberFromString(values.phone, 'US');
            phoneNumber = parsedPhoneNumber!.format('NATIONAL');
        }

        await handleSubmit({
            name: values.name,
            email: values.email,
            phone: phoneNumber,
            address: {
                address: values.address,
                city: values.city,
                state: values.state,
                postalCode: values.postalCode
            }
        }, type);

        if (this.form.current) {
            this.form.current.resetForm();
        }
    };
}

export default (VerifyContactInformationForm);
