import  {Component, ReactNode} from 'react';

class Logo extends Component
{
    public render() : ReactNode
    {
        return (
            <img
                alt="Aquatic Control"
                src="https://www.aquaticcontrol.com/wp-content/uploads/2018/01/logo-1.png"
                width="125"
                className="d-inline-block align-top"
            />
        );
    }
}

export default (Logo);
