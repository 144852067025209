import {Component, ReactNode} from "react";
import ModalBase from "./ModalBase";
import RejectionReasonForm from "../forms/RejectionReasonForm";

interface OwnProps
{
    show : boolean;
    handleClose : () => void;
    handleConfirm : (reason: string) => Promise<void>;
    proposalTitle: string;
    loading: boolean;
}

type Props = OwnProps;

class RejectLakeInformationModal extends Component<Props>
{
    public render() : ReactNode
    {
        const {show, handleConfirm, handleClose, proposalTitle, loading} = this.props;

        return (
            <ModalBase
                show={show}
                handleClose={handleClose}
                title="Reject Lake Information"
                confirmText="Confirm"
                refForm="rejectionReasonForm"
                size="md"
                btnClass="smallModalBtn"
                loading={loading}
            >
                <p>
                    You are rejecting the lake information provided for &nbsp;
                    <span className="text-primary">{proposalTitle}</span>. Please enter a rejection
                    reason below and click "<span className="text-primary">Confirm</span>" to proceed. An Aquatic
                    Control representative will review your provided rejection reason and you will be notified when
                    your proposal has been updated or in the event further information is required.
                    ~ Aquatic Control
                </p>
                <RejectionReasonForm handleSubmit={handleConfirm} />
            </ModalBase>
        );
    }
}

export default (RejectLakeInformationModal);
