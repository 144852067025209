import React, {Component, ReactNode} from 'react';

interface OwnProps {
    proposalTitle: string
}

type Props = OwnProps;

class LakeInformationRejected extends Component<Props>
{
    public render() : ReactNode
    {
        const {proposalTitle} = this.props;

        return (
            <React.Fragment>
                <div className="d-flex justify-content-center flex-column">
                    <p>
                        You have rejected the lake information provided for proposal &nbsp;
                        <span className="text-primary">{proposalTitle}</span>. Your rejection notice
                        has been submitted to the Aquatic Control team. An Aquatic Control representative will
                        review your provided rejection reason and you will be notified when your proposal has been
                        updated or in the event further information is required. ~Aquatic Control.
                    </p>
                </div>
            </React.Fragment>
        );
    }
}

export default (LakeInformationRejected);