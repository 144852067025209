import {Contact} from "../redux/contact/types";

export const apiEndpoint = import.meta.env.VITE_APP_API_ENDPOINT;

export const apiFetch = async (url : string, contact : Contact | null, init? : RequestInit) : Promise<Response> => {
    if (contact !== null && contact.token !== null) {
        if (!init) {
            init = {};
        }
        init.headers = init.headers instanceof Headers ? init.headers : new Headers(init.headers);
        init.headers.append('Authorization', `Bearer ${contact.token}`);
        init.headers.append('content-type', 'application/json');
    }

    return fetch(url, init);
};
