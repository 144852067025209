import {Component, ReactNode} from 'react';
import ModalBase from "../modal/ModalBase";
import {Payment, PaymentProfiles, Proposal} from "../../redux/contact/types";
import PaymentInformationForm from "../forms/PaymentInformationForm";
import {Button, Spinner} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {Jumbotron} from "../Jumbotron";

declare global {
    interface Window {
        AuthorizeNetIFrame?: { onReceiveCommunication: (queryStr: string) => void }
    }
}

type Message =
    | {
    action: 'resizeWindow'
    width: number
    height: number
}
    | {
    action: 'cancel'
}
    | {
    action: 'transactResponse'
    response: string
}| {
    action: 'successfulSave'
}

function isValidMessage(o: unknown): o is Message {
    return typeof o === 'object' && o !== null
}


const NoEmail = () => {
    return (<div className="alert alert-danger" role="alert">
        We are missing your email address.  Please <a href="tel:+1-800-753-5253">call us at 800-753-LAKE</a> to update your email address so you can make a payment.
    </div>)
}

interface OwnProps {
    proposalId: string,
    activeProposal: Proposal,
    getHostedToken: (proposalId: string, frequency: string, method: string) => void;
    hostedToken: string | null;
    paymentProfiles: Array<PaymentProfiles>;
    paymentProfileId: number | null;
    handlePaymentSubmit: (payment: Payment) => Promise<void>;
    updateFormValidationIsValid : (isValid : boolean) => void;
}

interface State {
    submitted: boolean;
    paymentMethod: string | null;
    frequency: string | null;
    loading: boolean;
    paymentDate: string | null;
    paymentProfileId: string | null;
    forFutureUse: boolean;
}

type Props = OwnProps;

class PaymentInformation extends Component<Props, State>
{
    public state = {
        submitted: false,
        paymentMethod: null,
        frequency: null,
        loading: false,
        paymentDate: null,
        paymentProfileId: null,
        forFutureUse: true,
    } as State

    public render() : ReactNode
    {
        const {
            activeProposal,
            handlePaymentSubmit,
            updateFormValidationIsValid,
            paymentProfileId
        } = this.props;

        const authNetPostUrlAdd = import.meta.env.VITE_APP_AUTHNET_POST_URL_ADD;
        const authNetPostUrlEdit = import.meta.env.VITE_APP_AUTHNET_POST_URL_EDIT;
        const merchantSealLink = import.meta.env.VITE_APP_MERCHANT_SEAL_LINK;
        const merchantSealImg = import.meta.env.VITE_APP_MERCHANT_SEAL_IMG;
        const emptyHtml = '/empty.html';
        const authNetTokenReady = (this.state.paymentMethod === 'credit' || this.state.paymentMethod === 'bank') &&
            this.props.hostedToken !== null && !this.state.loading;

        return (
            <>
                <div className="d-flex justify-content-center flex-column">
                    <div className="d-flex flex-row">
                        {activeProposal.isQuote ? (
                            <p className="pb-0 mb-0 pt-2">
                                Thank you for your accepted quote.  It has been received by our staff. Please continue with your payment selection.
                            </p>
                        ) : (
                        <p className="pb-0 mb-0 pt-2">
                            Thank you for your accepted proposal.  It has been received by our staff.  Please continue with your payment selection.
                        </p>
                        )}
                        <a href={merchantSealLink+window.location.href} target="_blank" rel="noopener noreferrer">
                            <img
                                src={merchantSealImg}
                                width="90"
                                height="72"
                                alt="Authorize.Net Merchant - Click to Verify"
                            />
                        </a>
                    </div>
                    <hr className="w-100" />
                    {activeProposal.proposalContact.email ? <PaymentInformationForm
                        payment={activeProposal.payment}
                        handleSubmit={handlePaymentSubmit}
                        updateFormValidationIsValid={updateFormValidationIsValid}
                        updatePaymentMethod={this.updatePaymentMethod}
                        updatePaymentFrequency={this.updatePaymentFrequency}
                        updatePaymentDate={this.updatePaymentDate}
                        totalMonthly={activeProposal.totalMonthly}
                        totalFull={activeProposal.totalFull}
                        proposalTotal={activeProposal.proposalTotal}
                        discountPercent={activeProposal.discountPercent}
                        installments={activeProposal.installments}
                        isPrior={activeProposal.isPrior}
                        proposalType={activeProposal.type}
                        maxDate={activeProposal.prepayExpires}
                        contractPeriodStartMonth={activeProposal.contractPeriodStartMonth}
                        paymentFrequency={this.state.frequency}
                        paymentDate={this.state.paymentDate ?? ''}
                        paymentProfiles={this.props.paymentProfiles}
                        authNetTokenReady={authNetTokenReady}
                        updatePaymentProfile={this.updatePaymentProfile}
                        paymentMethod={this.state.paymentMethod ?? ''}
                        paymentChoices={activeProposal.paymentChoices}
                        isQuote={activeProposal.isQuote}
                        priorTo={activeProposal.priorTo}
                    /> : <NoEmail/>}
                    {(authNetTokenReady && this.state.paymentDate && this.props.hostedToken) &&
                        this.state.paymentProfileId === '-1' && (
                    <div className="acceptHosted">
                        <form
                            onSubmit={() => this.setState({submitted: true})}
                            method={'POST'}
                            action={paymentProfileId ? authNetPostUrlEdit : authNetPostUrlAdd}
                            target={'iframeAuthorizeNet'}
                        >
                            <input name="token" type="hidden" value={this.props.hostedToken} />
                            <p>
                                <label><input name="forFutureUse" type="checkbox" checked={this.state.forFutureUse} onChange={() => this.toggleForFutureUse()}/> Save for future payments</label>
                            </p>
                            {paymentProfileId && <input name="paymentProfileId" type="hidden" value="" />}
                            {!this.state.submitted && (
                                <Button size="lg" variant="primary" type="submit" className="w-50">
                                    + Pay with new
                                    {this.state.paymentMethod === 'credit' ? ' Credit Card' : ' Bank Account'}
                                </Button>
                            )}
                        </form>
                        <ModalBase
                            show={this.state.submitted}
                            handleClose={this.onCancel}
                            title="Card Payment"
                            confirmText="Confirm"
                            size="lg"
                            btnClass="smallModalBtn"
                            hideCloseButton={true}
                            hideConfirmButton={true}
                            modalClass="paymentModal"
                            noPadding={true}
                        >
                            <div className="d-flex flex-row justify-content-center mt-0">
                            <a href={merchantSealLink+window.location.href} target="_blank" rel="noopener noreferrer">
                                <img
                                    src={merchantSealImg}
                                    width="90"
                                    height="72"
                                    alt="Authorize.Net Merchant - Click to Verify"
                                />
                            </a>
                            </div>
                        <iframe
                            id={'iframeAuthorizeNet'}
                            name={'iframeAuthorizeNet'}
                            frameBorder={0}
                            width={'100%'}
                            src={emptyHtml}
                            title="iframeAuthorizeNet"
                        />
                        </ModalBase>
                    </div>
                    )}
                    {(authNetTokenReady && this.state.paymentDate && this.props.hostedToken) &&
                    this.state.paymentProfileId && parseInt(this.state.paymentProfileId) > 0 && (
                            <Button size="lg" variant="primary" className="w-50" onClick={this.onTransact}>
                                + Pay with Selected
                                {this.state.paymentMethod === 'credit' ? ' Credit Card' : ' Bank Account'}
                            </Button>
                    )}
                    {this.state.loading && (
                        <Button
                            variant="primary"
                            size="lg"
                            type="submit"
                            className="w-50"
                            disabled
                        >
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            &nbsp; Loading
                        </Button>
                    )}
                    {this.state.paymentMethod === 'timely' && (
                        <Jumbotron className="d-flex flex-column p-3 mt-3 ps-3 pe-3">
                            <h3 className="text-primary">Please send timely payments to:</h3>
                            <p>Aquatic Control <br/>PO Box 100<br/>Seymour, IN 47274</p>
                            <p>Monthly invoices will be mailed.</p>
                        </Jumbotron>
                    )}
                </div>
            </>
        );
    }

    public async componentDidMount(): Promise<void> {

        window.AuthorizeNetIFrame = {
            onReceiveCommunication: queryStr => {
                this.onReceiveCommunication && this.onReceiveCommunication()
                const message = new URLSearchParams(queryStr);
                if (isValidMessage(message)) {
                    console.log('message', message);

                    switch (message.get('action')) {
                        case 'successfulSave'://called for both add and edit
                            this.onTransact && this.onTransact()
                            break

                        case 'cancel':
                            this.onCancel && this.onCancel()
                            break
                    }
                }
            }
        };

        return;
    }

    public componentWillUnmount(): void {
        delete window.AuthorizeNetIFrame;
    }

    private onReceiveCommunication = () => {
    }

    // @ts-ignore
    private onMessage = () => {
    }

    private onCancel = () => {
        this.setState({submitted: false});
    }

    private toggleForFutureUse = () => {
        this.setState({forFutureUse: !this.state.forFutureUse});
    }

    private onTransact = async () => {
        const {handlePaymentSubmit} = this.props;
        await handlePaymentSubmit({
            paymentDate: this.state.paymentDate,
            frequency: this.state.frequency,
            method: this.state.paymentMethod,
            paymentProfileId: this.state.paymentProfileId,
            forFutureUse: this.state.paymentProfileId === "-1" ? this.state.forFutureUse : true,
        });
        this.setState({submitted: false});
    }

    private updatePaymentFrequency = async (frequency: string) => {
        await this.setState({frequency: frequency});
        let paymentMethod = this.state.paymentMethod;
        if (null !== paymentMethod) {
            this.updatePaymentMethod(paymentMethod);
        }
    }

    private updatePaymentMethod = async (method: string | null) => {
        if (null !== this.state.frequency && method !== 'timely' && method !== 'net30' && method !== 'special' && method !== null && this.state.paymentDate !== '') {
            this.setState({loading: true});
            await this.props.getHostedToken(this.props.proposalId, this.state.frequency, method);
            this.setState({loading: false});
        }
        await this.setState({paymentMethod: method});
    }

    private updatePaymentDate = async (paymentDate : string | null) => {
        const method = this.state.paymentMethod;
        if (null !== this.state.frequency && method !== 'timely' && method !== 'net30' && method !== 'special' && method !== null && paymentDate !== '') {
            this.setState({paymentDate: paymentDate});
        } else {
            this.setState({paymentDate: paymentDate});
        }
    }

    private updatePaymentProfile = async (paymentProfileId : string | null) => {
        this.setState({paymentProfileId: paymentProfileId});
    }
}

export default (PaymentInformation);
